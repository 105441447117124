import Form from "../Form";
import Input from "../Input";
import { aspect4ProductVariantFormFields } from "./Aspect4ProductVariantFormFields";
import { Aspect4ProductVariantInterface } from "types/Aspect4ProductVariantInterface";

interface Props {
    defaultValues?: Aspect4ProductVariantInterface,
    onSubmit: (data: Aspect4ProductVariantInterface) => void,
}

const Aspect4ProductVariantForm = (props: Props) => {
    return (
        <Form
            onSubmit={() => { }}
            formOptions={{ defaultValues: props.defaultValues }}
        >
            <div className='grid gap-4 grid-cols-3'>
                <div><Input name={aspect4ProductVariantFormFields.gid.name} label={aspect4ProductVariantFormFields.gid.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.colourText.name} label={aspect4ProductVariantFormFields.colourText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.priceLevel.name} label={aspect4ProductVariantFormFields.priceLevel.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.activColour.name} label={aspect4ProductVariantFormFields.activColour.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.item.name} label={aspect4ProductVariantFormFields.item.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.ean.name} label={aspect4ProductVariantFormFields.ean.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.size.name} label={aspect4ProductVariantFormFields.size.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.itemStyle.name} label={aspect4ProductVariantFormFields.itemStyle.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.variant.name} label={aspect4ProductVariantFormFields.variant.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.colourCode.name} label={aspect4ProductVariantFormFields.colourCode.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.itemText.name} label={aspect4ProductVariantFormFields.itemText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductVariantFormFields.sizeNo.name} label={aspect4ProductVariantFormFields.sizeNo.label} disabled={true} /></div>
            </div>
        </Form>
    )
}

export default Aspect4ProductVariantForm;