import { FormFieldInterface } from "types/FormFieldInterface";
import FormFields from "../Form/FormFields";

class ShopifyProductVariantFormFields extends FormFields {
     public productId: FormFieldInterface = {
        name: 'productId',
        label: 'productId',
    }

    public title: FormFieldInterface = {
        name: 'title',
        label: 'title',
    }

    public sku: FormFieldInterface = {
        name: 'sku',
        label: 'sku',
    }

    public position: FormFieldInterface = {
        name: 'position',
        label: 'position',
    }

    public grams: FormFieldInterface = {
        name: 'grams',
        label: 'grams',
    }

    public inventoryPolicy: FormFieldInterface = {
        name: 'inventoryPolicy',
        label: 'inventoryPolicy',
    }

    public fulfillmentService: FormFieldInterface = {
        name: 'fulfillmentService',
        label: 'fulfillmentService',
    }

    public inventoryItemId: FormFieldInterface = {
        name: 'inventoryItemId',
        label: 'inventoryItemId',
    }

    public inventoryManagement: FormFieldInterface = {
        name: 'inventoryManagement',
        label: 'inventoryManagement',
    }

    public price: FormFieldInterface = {
        name: 'price',
        label: 'price',
    }

    public compareAtPrice: FormFieldInterface = {
        name: 'compareAtPrice',
        label: 'compareAtPrice',
    }

    public option1: FormFieldInterface = {
        name: 'option1',
        label: 'option1',
    }

    public option2: FormFieldInterface = {
        name: 'option2',
        label: 'option2',
    }

    public option3: FormFieldInterface = {
        name: 'option3',
        label: 'option3',
    }

    public createdAt: FormFieldInterface = {
        name: 'createdAt',
        label: 'createdAt',
    }

    public updatedAt: FormFieldInterface = {
        name: 'updatedAt',
        label: 'updatedAt',
    }

    public taxable: FormFieldInterface = {
        name: 'taxable',
        label: 'taxable',
    }

    public taxCode: FormFieldInterface = {
        name: 'taxCode',
        label: 'taxCode',
    }

    public requiresShipping: FormFieldInterface = {
        name: 'requiresShipping',
        label: 'requiresShipping',
    }

    public barcode: FormFieldInterface = {
        name: 'barcode',
        label: 'barcode',
    }

    public inventoryQuantity: FormFieldInterface = {
        name: 'inventoryQuantity',
        label: 'inventoryQuantity',
    }

    public imageId: FormFieldInterface = {
        name: 'imageId',
        label: 'imageId',
    }

    public weight: FormFieldInterface = {
        name: 'weight',
        label: 'weight',
    }

    public weightUnit: FormFieldInterface = {
        name: 'weightUnit',
        label: 'weightUnit',
    }

    public id: FormFieldInterface = {
        name: 'id',
        label: 'id',
    }

    public takeAction: FormFieldInterface = {
        name: 'takeAction',
        label: 'takeAction',
    }

    public takeActionApprovedOn: FormFieldInterface = {
        name: 'takeActionApprovedOn',
        label: 'takeActionApprovedOn',
    }

    public aspect4ProductVariantGid: FormFieldInterface = {
        name: 'aspect4ProductVariantGid',
        label: 'aspect4ProductVariantGid',
    }

    public shopifyShopGid: FormFieldInterface = {
        name: 'shopifyShopGid',
        label: 'shopifyShopGid',
    }

    public shopifyProductGid: FormFieldInterface = {
        name: 'shopifyProductGid',
        label: 'shopifyProductGid',
    }

}

export const shopifyProductVariantFormFields = new ShopifyProductVariantFormFields();