import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { StockInterface } from "types/StockInterface";
import { BaseStore } from "./BaseStore";

class StockStore extends BaseStore {
    stocks: PagingResultInterface<StockInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            stocks: observable,

            resetStore: action,
            getStocks: action
        })
    }
    
    resetStore = () => {
        this.stocks = null;
    }

    getStocks = (pageNumber: number = 1) => {
        return this.getPaged(`inventory`, this.stocks, 'stocks', pageNumber)
    }
}

export const stockStore = new StockStore();