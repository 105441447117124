import Aspect4ProductImagesThumbnails from "components/Aspect4ProductImagesThumbnails";
import ShopifyProductForm from "components/Forms/ShopifyProductForm";
import { shopifyProductFormFields } from "components/Forms/ShopifyProductForm/ShopifyProductFormFields";
import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { shopifyProductDetailsStore } from "stores/ShopifyProductDetailsStore";

const PageSynchronizeProductDetails = observer(() => {

    const { gid } = useParams();
    const navigate = useNavigate();
    const [showDebugData, setShowDebugData] = useState<boolean>(false);

    useEffect(() => {
        if (gid) {
            shopifyProductDetailsStore.getShopifyProduct(gid);
        }

        return () => {
            shopifyProductDetailsStore.resetStore();
        }
    }, [gid])

    const shopifyProduct = shopifyProductDetailsStore.shopifyProduct;

    const defaultValues = shopifyProductFormFields.extractDefaultValues(shopifyProductDetailsStore.shopifyProduct);

    const disableApproveAndIgnoreButtons = () => {
        return shopifyProduct?.takeAction === null ||
            shopifyProduct?.takeAction === 'ignore' ||
            shopifyProduct?.takeActionApprovedOn !== null;
    }

    const approveSyncAction = async () => {
        await shopifyProductDetailsStore.approveTakeAction(gid!);
        toast("Product synchronization approved. Synchronization will run within an hour.", { type: 'success' });
        shopifyProductDetailsStore.getShopifyProduct(gid!);
    }

    const setTakeAction = async (takeAction: string | null) => {
        await shopifyProductDetailsStore.setTakeAction(gid!, takeAction);
        if (takeAction === 'ignore') {
            toast("Product synchronization will be ignored until you reactivate 'Analyze'.", { type: 'success' });
        } else {
            toast("Sync. action has been resat. Product will be re-analyzed within an hour.", { type: 'success' });
        }
        shopifyProductDetailsStore.getShopifyProduct(gid!);
    }

    const markAsChange = (valueA?: any, valueB?: any) => {
        return (shopifyProduct?.takeAction === 'create' || valueA !== valueB ? 'text-red-500 ' : '') + ' align-text-top';
    }

    const gotoAspectProduct = () => {
        navigate(generatePath(routes.pageAspect4ProductDetails, { gid: shopifyProduct?.aspect4ProductGid }))
    }

    const stripHtmlFromString = (htmlString?: string): string => {
        return htmlString ? htmlString.replace(/(<([^>]+)>)/gi, "") : "";
    }

    if (!shopifyProduct) {
        return null;
    }

    // for comparison (markAsChange)
    const shopifyProductTags = shopifyProduct.tags.toLowerCase().split(',').sort().join(', ');
    const synchronizeShopifyProductTags = shopifyProduct.synchronizeProduct?.tags.toLowerCase().split(',').sort().join(', ');

    const synchronizeBodyHtml = () => {
        if (shopifyProduct.takeAction === 'create')
            return true;
            
        return shopifyProduct.bodyHtml.length === 0 
            && shopifyProduct.synchronizeProduct 
            && shopifyProduct.synchronizeProduct?.bodyHtml.length > 0;
    }

    return (
        <div>
            {shopifyProduct?.takeActionApprovedOn != null && <div className="float-right leading-10 text-green-600 font-bold ">Sync. pending</div>}
            {shopifyProduct?.takeActionApprovedOn == null && <div className="float-right">
                <button
                    type="button"
                    className="btn-primary mb-8 float-right"
                    disabled={disableApproveAndIgnoreButtons()}
                    onClick={() => approveSyncAction()}
                >
                    Approve Sync.
                </button>

                {
                    shopifyProduct.takeAction === 'ignore' && <button
                        type="button"
                        className="btn-secondary mb-8 float-right mr-4"
                        onClick={() => setTakeAction(null)}
                    >
                        Analyze
                    </button>
                }

                {
                    shopifyProduct.takeAction !== 'ignore' && <button
                        type="button"
                        className="btn-secondary mb-8 float-right mr-4"
                        disabled={disableApproveAndIgnoreButtons()}
                        onClick={() => setTakeAction('ignore')}
                    >
                        Ignore sync.
                    </button>
                }
            </div>
            }

            <h1>Synchronize Product Details</h1>
            <div className="py-4 mb-4">Properties and values marked in <span className="text-red-500">red</span> in the table below are the data that differ from Aspect4 and Shopify Shop.</div>
            <div className="py-4 mb-4 grid grid-cols-2 w-2/6">
                <strong>Sync. Action:</strong> <div>{shopifyProduct.takeAction}</div>
                <strong>Shopify Shop:</strong> <div>{shopifyProduct.shopifyShop?.name}</div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl">
                <table className="align-text-top w-full" cellPadding={10} cellSpacing={10}>
                    <thead>
                        <tr>
                            <td className="font-bold w-2/12"></td>
                            <td className="font-bold w-4/12">Shopify</td>
                            <td className="font-bold w-4/12">From Aspect4</td>
                            <td className="font-bold w-2/12">Synchronize</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={markAsChange(shopifyProduct.title, shopifyProduct.synchronizeProduct?.title)}>
                            <td className="w-2/12 font-bold">Title</td>
                            <td className="w-4/12">{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.title}</td>
                            <td className="w-4/12">{shopifyProduct.synchronizeProduct?.title}</td>
                            <td className="w-2/12">yes</td>
                        </tr>
                        <tr className={markAsChange(stripHtmlFromString(shopifyProduct.bodyHtml), shopifyProduct.synchronizeProduct?.bodyHtml)}>
                            <td className="font-bold">BodyHtml</td>
                            <td className="break-all">{shopifyProduct.takeAction === 'create' ? '' : stripHtmlFromString(shopifyProduct.bodyHtml)}</td>
                            <td className="break-all">{shopifyProduct.synchronizeProduct?.bodyHtml}</td>
                            <td>{synchronizeBodyHtml() ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProduct.vendor, shopifyProduct.synchronizeProduct?.vendor)}>
                            <td className="font-bold">Vendor</td>
                            <td>{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.vendor}</td>
                            <td>{shopifyProduct.synchronizeProduct?.vendor}</td>
                            <td>{shopifyProduct.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProduct.productType, shopifyProduct.synchronizeProduct?.productType)}>
                            <td className="font-bold">Product Type</td>
                            <td>{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.productType}</td>
                            <td>{shopifyProduct.synchronizeProduct?.productType}</td>
                            <td>{shopifyProduct.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductTags, synchronizeShopifyProductTags)}>
                            <td className="font-bold">Tags</td>
                            <td>{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.tags}</td>
                            <td>{shopifyProduct.synchronizeProduct?.tags}</td>
                            <td>{shopifyProduct.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProduct.handle, shopifyProduct.synchronizeProduct?.handle)}>
                            <td className="font-bold">Handle *</td>
                            <td>{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.handle}</td>
                            <td>{shopifyProduct.synchronizeProduct?.handle}</td>
                            <td>yes</td>
                        </tr>
                        <tr className={markAsChange(false, !!shopifyProduct.imageSync)}>
                            <td className="font-bold align-top">Product Images</td>
                            <td className="align-top">-</td>
                            <td className=" align-top">{shopifyProduct.imageSync ? <Aspect4ProductImagesThumbnails aspect4ProductGid={shopifyProduct.aspect4ProductGid!} /> : '-'}</td>
                            <td className="align-top">yes</td>
                        </tr>
                        <tr className={markAsChange(shopifyProduct.status, shopifyProduct.synchronizeProduct?.status)}>
                            <td className="font-bold">Status</td>
                            <td>{shopifyProduct.takeAction === 'create' ? '' : shopifyProduct.status}</td>
                            <td>{shopifyProduct.synchronizeProduct?.status}</td>
                            <td>yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="text-xs mt-4">* Shopify will ultimately determine the "handle" when the product is saved</div>

            <div className="clear-left mt-4">
                <button className="text-xs" onClick={() => setShowDebugData(!showDebugData)}>{showDebugData ? 'Hide Debug' : 'Debug'}</button>
            </div>
            {
                showDebugData && <div>
                    {
                        shopifyProduct.aspect4ProductGid && <button
                            className="my-8 btn bg-blue-500"
                            type="button"
                            onClick={() => gotoAspectProduct()}
                        >
                            Goto Aspect4 Product
                        </button>
                    }
                    {
                        !shopifyProduct.aspect4ProductGid && <span>No related Aspect4 Product found</span>
                    }

                    <ShopifyProductForm defaultValues={defaultValues} onSubmit={() => { }} />

                    <div className="">
                        <hr className="my-8" />
                        <h2>Shopify Product Variants</h2>

                        <div className="bg-white rounded-lg lg:w-1/2 p-4">
                            <div className="grid grid-cols-3 font-bold py-2 border-b ">
                                <div>Title</div>
                                <div>Price</div>
                                <div>Price</div>
                                <div>Sync. Action</div>
                            </div>
                            {
                                shopifyProduct.shopifyProductVariants?.map(shopifyProductVariant => {
                                    return <div
                                        key={shopifyProductVariant.gid}
                                        className={`grid grid-cols-3 py-2 cursor-pointer hover:opacity-75 ${shopifyProductVariant.archivedOn != null ? 'line-through' : ''}`}
                                        onClick={() => { navigate(generatePath(routes.pageSynchronizeProductVariantDetails, { gid: shopifyProductVariant.gid })) }}
                                    >
                                        <div>{shopifyProductVariant.title}</div>
                                        <div>{shopifyProductVariant.price?.toFixed(2)}</div>
                                        <div>{shopifyProductVariant.takeAction}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
})

export default PageSynchronizeProductDetails;