import Form from "../Form";
import Input from "../Input";
import { aspect4ProductFormFields } from "./Aspect4ProductFormFields";
import { Aspect4ProductInterface } from "types/Aspect4ProductInterface";

interface Props {
    defaultValues?: Aspect4ProductInterface,
    onSubmit: (data: Aspect4ProductInterface) => void,
}

const Aspect4ProductForm = (props: Props) => {
    return (
        <Form
            onSubmit={() => { }}
            formOptions={{ defaultValues: props.defaultValues }}
        >
            <div className='grid gap-4 grid-cols-3'>
                <div><Input name={aspect4ProductFormFields.gid.name} label={aspect4ProductFormFields.gid.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemColorText.name} label={aspect4ProductFormFields.itemColorText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemStyle.name} label={aspect4ProductFormFields.itemStyle.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemQuality.name} label={aspect4ProductFormFields.itemQuality.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picStyle.name} label={aspect4ProductFormFields.picStyle.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor.name} label={aspect4ProductFormFields.picColor.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor9.name} label={aspect4ProductFormFields.picColor9.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.brandText.name} label={aspect4ProductFormFields.brandText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor10.name} label={aspect4ProductFormFields.picColor10.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemGroup.name} label={aspect4ProductFormFields.itemGroup.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemBrand.name} label={aspect4ProductFormFields.itemBrand.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.brand.name} label={aspect4ProductFormFields.brand.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemGroupText.name} label={aspect4ProductFormFields.itemGroupText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.new.name} label={aspect4ProductFormFields.new.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.item.name} label={aspect4ProductFormFields.item.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemColor.name} label={aspect4ProductFormFields.itemColor.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor4.name} label={aspect4ProductFormFields.picColor4.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor3.name} label={aspect4ProductFormFields.picColor3.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor2.name} label={aspect4ProductFormFields.picColor2.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.collection.name} label={aspect4ProductFormFields.collection.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor8.name} label={aspect4ProductFormFields.picColor8.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.image2.name} label={aspect4ProductFormFields.image2.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor7.name} label={aspect4ProductFormFields.picColor7.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor6.name} label={aspect4ProductFormFields.picColor6.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.picColor5.name} label={aspect4ProductFormFields.picColor5.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.compText.name} label={aspect4ProductFormFields.compText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.activ.name} label={aspect4ProductFormFields.activ.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.itemText.name} label={aspect4ProductFormFields.itemText.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.compCode.name} label={aspect4ProductFormFields.compCode.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.collectionName.name} label={aspect4ProductFormFields.collectionName.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.fitting.name} label={aspect4ProductFormFields.fitting.label} disabled={true} /></div>
                <div><Input name={aspect4ProductFormFields.imagesModifiedOn.name} label={aspect4ProductFormFields.imagesModifiedOn.label} disabled={true} /></div>
            </div>
        </Form>
    )
}

export default Aspect4ProductForm;