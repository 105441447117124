import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Pagination from "components/Pagination";
import { routes } from "components/Routes";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { aspect4ProductStore } from "stores/Aspect4ProductsStore";
import { Aspect4ProductInterface } from "types/Aspect4ProductInterface";

const PageAspect4Products = observer(() => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') ?? '1'));
    const [searchQuery, setSearchQuery] = useState<string | null>(searchParams.get('search'));

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        setPage(parseInt(pageQuery));

        // filters
        setSearchQuery(searchParams.get('search'));

        return () => {
            aspect4ProductStore.resetStore();
        }
    }, [searchParams])

    // when page, search or action states changes
    useEffect(() => {
        aspect4ProductStore.getAspect4Products(page > 1 ? page : 1, searchQuery);
    }, [page, searchQuery])

    const filter = (data: { search: string }) => {
        let url = location.pathname + '?page=1';
        if (data.search) {
            url += '&search=' + data.search;
        }

        const dataSearch = (typeof data.search != 'undefined' && data.search ? data.search : null);

        // only navigate if query is different from current
        // (navigate doesn't trigger resetStore because it's the same path)
        if (dataSearch !== searchQuery) {
            aspect4ProductStore.resetStore();
            navigate(url);
        }
    }

    return (
        <div>
            <h1>Aspect4 Products</h1>
            <Form onSubmit={(data) => filter(data)} >
                <div className="mb-8">
                    <Input placeholder="Lookup Item Text or Item" name="search" className="w-2/6 float-left" defaultValue={searchQuery ?? undefined} />
                    <button type="submit" className="ml-4 btn-primary">Filter</button>
                </div>
            </Form>
            {
                aspect4ProductStore.aspect4Products?.items &&
                aspect4ProductStore.aspect4Products?.items.length > 0 &&
                <Table
                    data={aspect4ProductStore.aspect4Products.items}
                    head={[
                        { label: 'Item Text', sortProperty: '' },
                        { label: 'Item Color Text', sortProperty: '' },
                        { label: 'Item Group Text', sortProperty: '' },
                        { label: 'Item', sortProperty: '' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    onRowClicked={(aspect4Product: Aspect4ProductInterface) => navigate(generatePath(routes.pageAspect4ProductDetails, { gid: aspect4Product.gid }))}
                    renderRow={(aspect4Product: Aspect4ProductInterface) => {
                        return (
                            <>
                                <Cell>{aspect4Product.itemText}</Cell>
                                <Cell>{aspect4Product.itemColorText}</Cell>
                                <Cell>{aspect4Product.itemGroupText}</Cell>
                                <Cell>{aspect4Product.item}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                aspect4ProductStore.aspect4Products?.pagination &&
                <Pagination pagination={aspect4ProductStore.aspect4Products.pagination} />
            }
        </div>
    )
})

export default PageAspect4Products;