import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { resetPasswordTokenFormFields } from "./ResetPasswordTokenFormFields";
import { UserInterface } from "types/UserInterface";

interface Props {
    onSubmit: (data: UserInterface) => void,
}

const ResetPasswordTokenForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [resetPasswordTokenFormFields.password.name]: resetPasswordTokenFormFields.password.validation,
            [resetPasswordTokenFormFields.confirmPassword.name]: resetPasswordTokenFormFields.confirmPassword.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-1'>
                <div>
                    <Input type="password" name={resetPasswordTokenFormFields.password.name} label={resetPasswordTokenFormFields.password.label} />
                </div>
                <div>
                    <Input type="password" name={resetPasswordTokenFormFields.confirmPassword.name} label={resetPasswordTokenFormFields.confirmPassword.label} />
                </div>
            </div>
            {
                <Button
                    type='submit'
                    label='Reset'
                    appearance="primary"
                />
            }
        </Form>
    )
}


export default ResetPasswordTokenForm;