import { useFormContext } from "react-hook-form";

interface Props {
    label: string,
    onClick?: () => void,
    className?: string,
    type: 'button' | 'submit' | 'reset',
    appearance: 'primary' | 'secondary',
    disabled?: boolean
}

const Button = (props: Props) => {
    const ctx = useFormContext();
    const isSubmitting = ctx?.formState?.isSubmitting || false;

    const primaryClass = 'btn-primary';
    const secondaryClass = 'btn-secondary';

    return (
        <button
            disabled={props.disabled || (props.type === 'submit' && isSubmitting)}
            type={props.type}
            onClick={props.onClick}
            className={`
                ${props.appearance === 'primary' ? primaryClass : ''}
                ${props.appearance === 'secondary' ? secondaryClass : ''}
                ${props.className || ''}
            `}>
            {props.label}
        </button>
    )

}

export default Button;

