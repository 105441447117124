import ShopifyShopForm from "components/Forms/ShopifyShopForm";
import { shopifyShopFormFields } from "components/Forms/ShopifyShopForm/ShopifyShopFormFields";
import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { shopifyShopDetailsStore } from "stores/ShopifyShopDetailsStore";
import { ShopifyShopInterface } from "types/ShopifyShopInterface";

const PageShopifyShopDetails = observer(() => {

    const navigate = useNavigate();
    const { gid } = useParams();

    useEffect(() => {
        if (gid) {
            shopifyShopDetailsStore.getShopifyShop(gid);
        }

        return () => {
            shopifyShopDetailsStore.resetStore();
        }
    }, [gid])

    const onSubmit = async (data: ShopifyShopInterface) => {
        try {
            if (shopifyShopDetailsStore.shopifyShop?.gid) {
                data.gid = shopifyShopDetailsStore.shopifyShop?.gid;
                await shopifyShopDetailsStore.updateShopifyShop(data)
            } else {
                await shopifyShopDetailsStore.createShopifyShop(data)
            }
            navigate(generatePath(routes.pageShopifyShops))
            toast('Shopify Shop saved successfully', { type: 'success' })
        } catch (err) {
            toast('An error occured saving Shopify Shop', { type: 'error' })
        }
    }

    const defaultValues = shopifyShopFormFields.extractDefaultValues(shopifyShopDetailsStore.shopifyShop);

    return (
        <div>
            <h1>Shopify Shop</h1>
            <ShopifyShopForm onSubmit={onSubmit} defaultValues={defaultValues} />
            <>
                <hr className="my-8" />
                <h2>Fulfillment services</h2>
                {
                    shopifyShopDetailsStore.shopifyShop?.shopifyFulfillmentServices?.map((shopifyFulfillmentService) => {
                        return <div key={shopifyFulfillmentService.gid}>
                            {shopifyFulfillmentService.name}
                        </div>
                    })
                }

            </>
        </div>
    )
})

export default PageShopifyShopDetails;