import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { createUserFormFields } from "./CreateUserFormFields";
import { UserInterface } from "types/UserInterface";

interface Props {
    defaultValues?: UserInterface,
    onSubmit: (data: UserInterface) => void,
}

const CreateUserForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [createUserFormFields.fullName.name]: createUserFormFields.fullName.validation,
            [createUserFormFields.email.name]: createUserFormFields.email.validation,
            [createUserFormFields.verified.name]: createUserFormFields.verified.validation,
            [createUserFormFields.password.name]: createUserFormFields.password.validation,
            [createUserFormFields.confirmPassword.name]: createUserFormFields.confirmPassword.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            formOptions={{ defaultValues: props.defaultValues }}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-2'>
                <div>
                    <Input name={createUserFormFields.fullName.name} label={createUserFormFields.fullName.label} description={createUserFormFields.fullName.description} />
                </div>
                <div>
                    <Input name={createUserFormFields.email.name} label={createUserFormFields.email.label} description={createUserFormFields.email.description} />
                </div>
                <div>
                    <Input name={createUserFormFields.password.name} label={createUserFormFields.password.label} description={createUserFormFields.password.description} />
                </div>
                <div>
                    <Input name={createUserFormFields.confirmPassword.name} label={createUserFormFields.confirmPassword.label} description={createUserFormFields.confirmPassword.description} />
                </div>
                <div>
                    <Input name={createUserFormFields.verified.name} label={createUserFormFields.verified.label} description={createUserFormFields.verified.description} type="checkbox" />
                </div>
            </div>
            {
                <Button
                    type='submit'
                    label='Save'
                    appearance="primary"
                />
            }
        </Form>
    )
}

export default CreateUserForm;