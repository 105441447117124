import MenuItem from "components/MenuItem";
import { routes } from "components/Routes";
import SubSubMenuItem from "components/SubSubMenuItem";
import SubMenuItem from "components/SubMenuItem";
import { useNavigate, useLocation } from "react-router-dom";

const LeftPane = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="basis-2/12">
            <MenuItem
                label="Synchronize"
                onClick={() => navigate(routes.pageSynchronizeProducts)}
                isActive={location.pathname.indexOf('/synchronize') !== -1}
            />
            <SubMenuItem
                label="Products"
                onClick={() => navigate(routes.pageSynchronizeProducts)}
                isActive={location.pathname.indexOf(routes.pageSynchronizeProducts) !== -1}
            />
            <SubMenuItem
                label="Variants"
                onClick={() => navigate(routes.pageSynchronizeProductVariants)}
                isActive={location.pathname.indexOf(routes.pageSynchronizeProductVariants) !== -1}
            />

            
            <MenuItem
                onClick={() => navigate(routes.pageAspect4Products)}
                label={"Aspect4"}
                isActive={location.pathname.indexOf('/aspect4') !== -1}
            />
            <SubMenuItem
                label="Products"
                onClick={() => navigate(routes.pageAspect4Products)}
                isActive={location.pathname.indexOf(routes.pageAspect4Products) !== -1}
            />
            <SubMenuItem
                label="Variants"
                onClick={() => navigate(routes.pageAspect4ProductVariants)}
                isActive={location.pathname.indexOf(routes.pageAspect4ProductVariants) !== -1}
            />
            <SubMenuItem
                label="Images"
                onClick={() => navigate(routes.pageAspect4ProductImages)}
                isActive={location.pathname.indexOf(routes.pageAspect4ProductImages) !== -1}
            />


            <MenuItem
                label={"Settings"}
                onClick={() => navigate(routes.pageInventoryDownloaded)}
                isActive={location.pathname.indexOf('/settings') !== -1}
            />


            {location.pathname.indexOf('/settings') !== -1 && <>

                <SubMenuItem
                    label={"Inventory"}
                    onClick={() => navigate(routes.pageInventoryDownloaded)}
                    isActive={location.pathname.indexOf('/settings/inventory') !== -1}
                />
                <SubSubMenuItem

                    label="Downloaded"
                    onClick={() => navigate(routes.pageInventoryDownloaded)}
                    isActive={location.pathname.indexOf(routes.pageInventoryDownloaded) !== -1}
                />
                <SubSubMenuItem
                    label="Received"
                    onClick={() => navigate(routes.pageInventoryReceived)}
                    isActive={location.pathname.indexOf(routes.pageInventoryReceived) !== -1}
                />


                <SubMenuItem
                    label={"Logs"}
                    onClick={() => navigate(routes.pageLogsAnalyze)}
                    isActive={location.pathname.indexOf('settings/logs') !== -1}
                />
                <SubSubMenuItem
                    label="Analyze"
                    onClick={() => navigate(routes.pageLogsAnalyze)}
                    isActive={location.pathname.indexOf(routes.pageLogsAnalyze) !== -1}
                />
                <SubSubMenuItem
                    label="Synchronize"
                    onClick={() => navigate(routes.pageLogsSynchronize)}
                    isActive={location.pathname.indexOf(routes.pageLogsSynchronize) !== -1}
                />


                <SubMenuItem
                    label={"Access"}
                    onClick={() => navigate(routes.pageUsers)}
                    isActive={location.pathname.indexOf('/settings/access') !== -1}
                />
                <SubSubMenuItem
                    label="Users"
                    onClick={() => navigate(routes.pageUsers)}
                    isActive={location.pathname.indexOf(routes.pageUsers) !== -1}
                />
                <SubSubMenuItem
                    label="Shops"
                    onClick={() => navigate(routes.pageShopifyShops)}
                    isActive={location.pathname.indexOf(routes.pageShopifyShops) !== -1}
                />
            </>}
        </div>
    )
}

export default LeftPane;