import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Pagination from "components/Pagination";
import { routes } from "components/Routes";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { aspect4ProductVariantStore } from "stores/Aspect4ProductVariantStore";
import { Aspect4ProductVariantInterface } from "types/Aspect4ProductVariantInterface";

const PageAspect4ProductVariants = observer(() => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') ?? '1'));
    const [searchQuery, setSearchQuery] = useState<string | null>(searchParams.get('search'));

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        setPage(parseInt(pageQuery));

        // filters
        setSearchQuery(searchParams.get('search'));

        return () => {
            aspect4ProductVariantStore.resetStore();
        }
    }, [searchParams])

    // when page, search or action states changes
    useEffect(() => {
        aspect4ProductVariantStore.getAspect4ProductVariants(page > 1 ? page : 1, searchQuery);
    }, [page, searchQuery])

    const filter = (data: { search: string }) => {
        let url = location.pathname + '?page=1';
        if (data.search) {
            url += '&search=' + data.search;
        }

        const dataSearch = (typeof data.search != 'undefined' && data.search ? data.search : null);

        // only navigate if query is different from current
        // navigate doesn't trigger resetStore because it's the same path
        if (dataSearch !== searchQuery) {
            aspect4ProductVariantStore.resetStore();
            navigate(url);
        }
    }

    return (
        <div>
            <h1>Aspect4 Product Variants</h1>
            <Form onSubmit={(data) => filter(data)} >
                <div className="mb-8">
                    <Input placeholder="Lookup Size, Item Text or Variant" name="search" className="w-2/6 float-left" defaultValue={searchQuery ?? undefined} />
                    <button type="submit" className="ml-4 btn-primary">Filter</button>
                </div>
            </Form>
            {
                aspect4ProductVariantStore.aspect4ProductVariants?.items &&
                aspect4ProductVariantStore.aspect4ProductVariants?.items.length > 0 &&
                <Table
                    data={aspect4ProductVariantStore.aspect4ProductVariants.items}
                    head={[
                        { label: 'Size', sortProperty: '' },
                        { label: 'Size No', sortProperty: '' },
                        { label: 'Item Text', sortProperty: '' },
                        { label: 'Colour Text', sortProperty: '' },
                        { label: 'Ean', sortProperty: '' },
                        { label: 'Variant', sortProperty: '' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    onRowClicked={(aspect4ProductVariant: Aspect4ProductVariantInterface) => navigate(generatePath(routes.pageAspect4ProductVariantDetails, { gid: aspect4ProductVariant.gid }))}
                    renderRow={(aspect4ProductVariant: Aspect4ProductVariantInterface) => {
                        return (
                            <>
                                <Cell>{aspect4ProductVariant.size}</Cell>
                                <Cell>{aspect4ProductVariant.sizeNo}</Cell>
                                <Cell>{aspect4ProductVariant.itemText}</Cell>
                                <Cell>{aspect4ProductVariant.colourText}</Cell>
                                <Cell>{aspect4ProductVariant.ean}</Cell>
                                <Cell>{aspect4ProductVariant.variant}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                aspect4ProductVariantStore.aspect4ProductVariants?.pagination &&
                <Pagination pagination={aspect4ProductVariantStore.aspect4ProductVariants.pagination} />
            }
        </div>
    )
})

export default PageAspect4ProductVariants;