import { StatusCodes } from "http-status-codes";
import { action, makeObservable, observable } from "mobx";
import { UserInterface } from "types/UserInterface";

import { BaseStore } from "./BaseStore";

class UserDetailsStore extends BaseStore {
    user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            user: observable,

            resetStore: action,
            getUser: action
        })
    }

    resetStore = () => {
        this.user = null;
    }

    getUser = (gid: string) => {
        return this.get(`users/${gid}`, 'user');
    }

    createUser = (data: UserInterface) => {
        return this.post(`users`, data, 'user');
    }

    updateUser = (data: UserInterface) => {
        return this.put(`users/${this.user!.gid}`, data, 'user');
    }
}

export const userDetailsStore = new UserDetailsStore();