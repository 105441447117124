import FileUpload from "components/FileUpload";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { aspect4ProductImagesStore } from "stores/Aspect4ProductImagesStore";
import { Aspect4ProductImageInterface } from "types/Aspect4ProductImage"

interface Props {
    aspect4ProductGid: string,
}

const Aspect4ProductImages = observer((props: Props) => {

    useEffect(() => {
        aspect4ProductImagesStore.getAspect4ProductImages(props.aspect4ProductGid);
        return () => {
            aspect4ProductImagesStore.resetStore();
        }
    }, [props.aspect4ProductGid]);

    const images = _.sortBy(aspect4ProductImagesStore.aspect4ProductImages, ['position']);

    const changeImagePosition = async (productImageGid: string, relativeNewPosition: number) => {

        if (images === null || images.length < 2) {
            return;
        }

        let swapImages: Aspect4ProductImageInterface[] = images;

        // get image
        let imageA = swapImages.find(x => x.gid === productImageGid);
        if (imageA === undefined) {
            return;
        }

        let imageB = swapImages.find(x => x.position === imageA!.position + relativeNewPosition);
        if (imageB === undefined) {
            return;
        }

        // move / swap images and sort by position 
        imageB.position = imageA.position;
        imageA.position = imageA.position + relativeNewPosition;
        swapImages = _.sortBy(swapImages, ['position']);

        // get product image gid sorted by position
        let newImagePositions = swapImages.map(aspect4ProductImage => aspect4ProductImage.gid);

        try {
            await aspect4ProductImagesStore.setImagePositions(props.aspect4ProductGid, newImagePositions);
        } catch (err) {
            // force "reload" when image positions could be updated
            aspect4ProductImagesStore.resetStore();
            await aspect4ProductImagesStore.getAspect4ProductImages(props.aspect4ProductGid);
            toast('An error occured trying to set new image positions', { type: 'error' });
        }
    }

    const deleteImage = async (productImageGid: string) => {
        if (images === null) {
            return;
        }

        await aspect4ProductImagesStore.deleteImage(props.aspect4ProductGid, productImageGid);
    }

    return <div>
        <h2>Images</h2>

        <FileUpload
            uploadPostUrl={`/aspect4/internal/products/${props.aspect4ProductGid}/images`}
            uploadCallback={() => aspect4ProductImagesStore.getAspect4ProductImages(props.aspect4ProductGid)}
        />

        <div className="text-sm">
            {
                images && images.map((aspect4ProductImage, index) => {
                    return <div key={aspect4ProductImage.gid} className="inline-block mr-4 mb-4">
                        <div className="flex justify-center bg-gray-100 p-2 rounded-t-sm relative">
                            <button
                                type="button"
                                className="rounded-full w-6 h-6 bg-red-500 text-white absolute -right-2 -top-2 shadow-md"
                                onClick={() => deleteImage(aspect4ProductImage.gid)}
                            >
                                &#x2715;
                            </button>
                            <img
                                src={aspect4ProductImage.url}
                                className="object-contain max-h-44 "
                                alt={aspect4ProductImage.name}
                            />
                        </div>
                        <div className="grid grid-cols-6 bg-white p-2 rounded-b-sm">
                            <button
                                type="button"
                                className="rounded-full w-6 h-6 bg-black text-white"
                                onClick={() => changeImagePosition(aspect4ProductImage.gid, -1)}
                                disabled={index === 0}
                            >
                                &#x276E;
                            </button>
                            <div className="col-span-4 text-center">
                                {aspect4ProductImage.name.substring(0, 20)}
                                {aspect4ProductImage.name.length > 20 ? '...' : ''}
                            </div>
                            <div className="text-right">
                                <button
                                    type="button"
                                    className="rounded-full w-6 h-6 bg-black text-white"
                                    onClick={() => changeImagePosition(aspect4ProductImage.gid, 1)}
                                    disabled={index === images.length - 1}
                                >
                                    &#x276F;
                                </button>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
});

export default Aspect4ProductImages;