import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { aspect4ProductImagesStore } from "stores/Aspect4ProductImagesStore";

interface Props {
    aspect4ProductGid: string
}

const Aspect4ProductImagesThumbnails = observer((props: Props) => {

    // Fetch Aspect4Product images
    useEffect(() => {
        aspect4ProductImagesStore.getAspect4ProductImages(props.aspect4ProductGid);

        return () => {
            aspect4ProductImagesStore.resetStore();
        }
    }, [props.aspect4ProductGid]);

    const images = _.sortBy(aspect4ProductImagesStore.aspect4ProductImages, ['position']);

    if (images.length === 0) {
        return <div>-</div>
    }

    return <div className="border p-1">
        {
            images && images.map((aspect4ProductImage, index) => {
                return <img
                    src={aspect4ProductImage.url}
                    className="float-left max-w-20 max-h-20 m-1"
                    alt={aspect4ProductImage.name}
                    key={aspect4ProductImage.gid}
                />
            })
        }
        <div className="clear-both"></div>
    </div>



});

export default Aspect4ProductImagesThumbnails;