import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { ShopifyShopInterface } from "types/ShopifyShopInterface";
import { BaseStore } from "./BaseStore";

class ShopifyShopsStore extends BaseStore {
    shopifyShops: PagingResultInterface<ShopifyShopInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyShops: observable,

            resetStore: action,
            getShopifyShops: action
        })
    }

    resetStore = () => {
        this.shopifyShops = null;
    }

    getShopifyShops = (pageNumber: number) => {
        return this.getPaged(`shopify/internal/shops`, this.shopifyShops, 'shopifyShops', pageNumber)
    }
}

export const shopifyShopsStore = new ShopifyShopsStore();