

import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { resetPasswordFormFields } from "./ResetPasswordFormFields";
import { UserInterface } from "types/UserInterface";

interface Props {
    onSubmit: (data: UserInterface) => void,
}

const ResetPasswordForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [resetPasswordFormFields.email.name]: resetPasswordFormFields.email.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-1'>
                <div>
                    <Input name={resetPasswordFormFields.email.name} label={resetPasswordFormFields.email.label} />
                </div>
            </div>
            {
                <Button
                    type='submit'
                    label='Reset'
                    appearance="primary"
                />
            }
        </Form>
    )
}


export default ResetPasswordForm;