import Routes from 'components/Routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/tracing';

import 'normalize.css';
import 'styles/global.scss';

// enable sentry if dsn and traces sample rate is set
if (
    process.env.REACT_APP_SENTRY_DSN !== undefined
    && process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE !== undefined
) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE as any,
    });
}

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(<Routes />);
