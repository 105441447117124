// Shopify Product Image validation rules
const maxFileSizeInBytes = 20 * 1024 * 1024; // 20 mb
const maxImageHeightInPixels = 4472;
const maxImageWidthInPixels = 4472;

export async function isShopifyImageValid(file: File) {
    if (file.size > maxFileSizeInBytes)
        return false;

    // Validate image dimensions
    let img = await addImageProcess(window.URL.createObjectURL(file));

    if (img === null)
        return false;

    if ((img.width && img.width > maxImageWidthInPixels) || (img.height && img.height > maxImageHeightInPixels)) {
        img.remove();
        return false;
    }

    img.remove();
    return true;
}

function addImageProcess(src: string) {
    return new Promise<HTMLImageElement | null>((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    })
}