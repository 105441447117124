import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { SynchronizeLogInterface } from "types/SynchronizeLogInterface";
import { BaseStore } from "./BaseStore";

class SynchronizeLogStore extends BaseStore {
    synchronizeLogs: PagingResultInterface<SynchronizeLogInterface> | null = null;
    
    constructor() {
        super();

        makeObservable(this, {
            synchronizeLogs: observable,

            resetStore: action,
            getSynchronizeLogs: action
        })
    }

    resetStore = () => {
        this.synchronizeLogs = null;
    }

    getSynchronizeLogs = (pageNumber: number) => {
        return this.getPaged(`synchronize/logs`, this.synchronizeLogs, 'synchronizeLogs', pageNumber)
    }
}

export const synchronizeLogStore = new SynchronizeLogStore();