import Pagination from "components/Pagination";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { synchronizeLogStore } from "stores/SynchronizeLogStore";
import { SynchronizeLogInterface } from "types/SynchronizeLogInterface";

const PageLogsSynchronize = observer(() => {

    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        synchronizeLogStore.getSynchronizeLogs(page > 1 ? page : 1);

        return () => {
            synchronizeLogStore.resetStore();
        }
    }, [searchParams])

    return (
        <div>
            <h1>Synchronize Log</h1>
            {
                synchronizeLogStore.synchronizeLogs?.items &&
                synchronizeLogStore.synchronizeLogs?.items.length > 0 &&
                <Table
                    data={synchronizeLogStore.synchronizeLogs.items}
                    head={[
                        { label: 'Gid', sortProperty: '' },
                        { label: 'From Entity', sortProperty: '' },
                        { label: 'From Entity Id', sortProperty: '' },
                        { label: 'To Entity', sortProperty: '' },
                        { label: 'To Entity Id', sortProperty: '' },
                        { label: 'Action Type', sortProperty: '' },
                        { label: 'Message', sortProperty: '' },
                        { label: 'Status Type', sortProperty: '' },
                        { label: 'Date', sortProperty: '' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    renderRow={(synchronizeLog: SynchronizeLogInterface) => {
                        return (
                            <>
                                <Cell>{synchronizeLog.gid}</Cell>
                                <Cell>{synchronizeLog.fromEntity}</Cell>
                                <Cell>{synchronizeLog.fromEntityId}</Cell>
                                <Cell>{synchronizeLog.toEntity}</Cell>
                                <Cell>{synchronizeLog.toEntityId}</Cell>
                                <Cell>{synchronizeLog.actionType}</Cell>
                                <Cell>{synchronizeLog.message}</Cell>
                                <Cell>{synchronizeLog.statusType}</Cell>
                                <Cell width={150}>{moment(synchronizeLog.createdOn).format('YYYY-MM-DD HH:mm')}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                synchronizeLogStore.synchronizeLogs?.pagination &&
                <Pagination pagination={synchronizeLogStore.synchronizeLogs.pagination} />
            }
        </div>
    )
})

export default PageLogsSynchronize;