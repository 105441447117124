import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { UserInterface } from "types/UserInterface";
import { BaseStore } from "./BaseStore";

class UsersStore extends BaseStore {
    users: PagingResultInterface<UserInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            users: observable,

            resetStore: action,
            getUsers: action
        })
    }

    resetStore = () => {
        this.users = null;
    }

    getUsers = (pageNumber: number) => {
        return this.getPaged(`users`, this.users, 'users', pageNumber)
    }
}

export const usersStore = new UsersStore();