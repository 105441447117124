import { FormFieldInterface } from "types/FormFieldInterface";
import * as yup from "yup";
import FormFields from "../Form/FormFields";

class ShopifyShopFormFields extends FormFields{
    public gid: FormFieldInterface = {
        name: 'gid',
        label: '',
        validation: yup.string().required('Required'),
    }

    public name: FormFieldInterface = {
        name: 'name',
        label: 'Name',
        validation: yup.string().required('Required')
    }

    public languageCode: FormFieldInterface = {
        name: 'languageCode',
        label: 'Language Code',
        description: "Has to match 'Aspect4 Product Text Language'",
        validation: yup.string().required('Required')
    }

    public currency: FormFieldInterface = {
        name: 'currency',
        label: 'Currency',
        description: "Has to match 'Aspect4 Product Price Currency'",
        validation: yup.string().required('Required')
    }

    public priceList: FormFieldInterface = {
        name: 'priceList',
        label: 'Price List',
        description: "Has to match 'Aspect4 Product Price Price List'",
        validation: yup.string().required('Required')
    }

    public shopifyUrl: FormFieldInterface = {
        name: 'shopifyUrl',
        label: 'Shopify Url',
        validation: yup.string().required('Required')
    }

    public readyForAnalyze: FormFieldInterface = {
        name: 'readyForAnalyze',
        label: 'Ready For Analyze',
        description: "Should be set to false as default (on create)",
        validation: yup.bool(),
    }

    public apiAccessToken: FormFieldInterface = {
        name: 'apiAccessToken',
        label: 'Api Access Token',
        validation: yup.string().required('Required')
    }

    public apiKey: FormFieldInterface = {
        name: 'apiKey',
        label: 'Api Key',
        validation: yup.string().required('Required')
    }

    public apiSecretKey: FormFieldInterface = {
        name: 'apiSecretKey',
        label: 'Api Secret Key',
        validation: yup.string().required('Required')
    }
}

export const shopifyShopFormFields = new ShopifyShopFormFields();