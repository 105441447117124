import ResetPasswordForm from "components/Forms/ResetPasswordForm";
import Logo from "components/Logo";
import { routes } from "components/Routes";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";

const PageResetPassword = () => {

    const navigate = useNavigate();

    const onSubmit = async (data: { email: string }) => {
        try {
            await authStore.resetPassword(data.email);
            toast('Reset password instructions has been sent to your email.', { type: 'success' });
            
        }
        catch (error) {
            toast('Invalid credentials', { type: 'error' });
        }
        navigate(routes.pageLogin);
    }

    return (
        <div className="flex mt-32 justify-center">
            <div className="basis-1/4">
                <div className="mb-4">
                    <Logo />
                </div>
                <div>
                    <ResetPasswordForm onSubmit={onSubmit} />
                </div>
            </div>
        </div>
    )
}

export default PageResetPassword;