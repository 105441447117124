import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { ShopifyProductInterface } from "types/ShopifyProductInterface";
import { BaseStore } from "./BaseStore";

class ShopifyProductStore extends BaseStore {
    shopifyProducts: PagingResultInterface<ShopifyProductInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyProducts: observable,

            resetStore: action,
            getShopifyProducts: action,
            approveAll: action,
            approveSelection: action,
        })
    }

    resetStore = () => {
        this.shopifyProducts = null;
    }

    getShopifyProducts = (pageNumber: number, takeAction: string | null = null, search: string | null = null) => {
        const url = `shopify/internal/products?`
            + (takeAction != null ? `&takeAction=${takeAction}` : ``)
            + (search != null ? `&search=${search}` : ``);

        return this.getPaged(url, this.shopifyProducts, 'shopifyProducts', pageNumber)
    }

    approveAll = () => {
        return this.put('shopify/internal/products/approve-all-take-action', {}, null);
    }

    approveSelection = (gids: string[]) => {
        const data = {
            shopifyProductGuids: gids
        };
        return this.put(`shopify/internal/products/approve-take-action`, data, null);
    }
}

export const shopifyProductStore = new ShopifyProductStore();