import { action, makeObservable, observable } from "mobx";
import { ShopifyProductInterface } from "types/ShopifyProductInterface";
import { BaseStore } from "./BaseStore";

class ShopifyProductDetailsStore extends BaseStore {
    shopifyProduct: ShopifyProductInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyProduct: observable,

            resetStore: action,
            getShopifyProduct: action,
            setTakeAction: action
        })
    }

    resetStore = () => {
        this.shopifyProduct = null;
    }

    getShopifyProduct = (gid: string) => {
        return this.get(`shopify/internal/products/${gid}`, 'shopifyProduct');
    }

    approveTakeAction = (gid: string) => {
        const data = {
            shopifyProductGuids: [gid]
        };
        return this.put(`shopify/internal/products/approve-take-action`, data, null);
    }

    setTakeAction = (gid: string, takeAction: string|null) => {
        const data = {
            shopifyProductGuids: [gid],
            takeAction: takeAction,
        };
        return this.put(`shopify/internal/products/set-take-action`, data, null);
    }
}

export const shopifyProductDetailsStore = new ShopifyProductDetailsStore();