import ResetPasswordTokenForm from "components/Forms/ResetPasswordTokenForm";
import Logo from "components/Logo";
import { routes } from "components/Routes";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";

const PageResetPasswordToken = () => {

    const { token } = useParams();
    const navigate = useNavigate();

    const onSubmit = async (data: { password: string }) => {
        if (token === undefined) {
            toast('Invalid token', { type: 'error' });
        }
        else {
            try {
                await authStore.setPassword(token, data.password);
                toast('Password updated', { type: 'success' });
            }
            catch (error) {
                // store handles message
            }
        }
        navigate(routes.pageLogin);
    }

    return (
        <div className="flex mt-32 justify-center">
            <div className="basis-1/4">
                <div className="mb-4">
                    <Logo />
                </div>
                <div>
                    <ResetPasswordTokenForm onSubmit={onSubmit} />
                </div>
            </div>
        </div>
    )
}

export default PageResetPasswordToken;