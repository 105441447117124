import { action, makeObservable, observable } from "mobx";
import { Aspect4ProductInterface } from "types/Aspect4ProductInterface";
import { BaseStore } from "./BaseStore";

class Aspect4ProductDetailsStore extends BaseStore {
    aspect4Product: Aspect4ProductInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4Product: observable,

            resetStore: action,
            getAspect4Product: action
        })
    }

    resetStore = () => {
        this.aspect4Product = null;
    }

    getAspect4Product = (gid: string) => {
        return this.get(`aspect4/internal/products/${gid}`, 'aspect4Product');
    }
}

export const aspect4ProductDetailsStore = new Aspect4ProductDetailsStore();