import { action, makeObservable, observable } from "mobx";
import { AnalyzeLogInterface } from "types/AnalyzeLogInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class AnalyzeLogStore extends BaseStore {
    analyzeLogs: PagingResultInterface<AnalyzeLogInterface> | null = null;
    
    constructor() {
        super();

        makeObservable(this, {
            analyzeLogs: observable,

            resetStore: action,
            getAnalyzeLogs: action
        })
    }

    resetStore = () => {
        this.analyzeLogs = null;
    }

    getAnalyzeLogs = (pageNumber: number) => {
        return this.getPaged(`analyze/logs`, this.analyzeLogs, 'analyzeLogs', pageNumber)
    }
}

export const analyzeLogStore = new AnalyzeLogStore();