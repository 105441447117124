interface Props {
    label: string,
    onClick?: () => void,
    isActive?: boolean,
    style?: React.CSSProperties
}

const SubSubMenuItem = (props: Props) => {
    return (
        <li
            style={props.style}
            className={`mb-4 ml-8 list-none text-lg ${!props.isActive ? 'opacity-75' : ''}`
        }>
            <button
                className={props.isActive ? '' : ''}
                onClick={props.onClick}
                type='button'>{props.label}</button>
        </li>
    )
}

export default SubSubMenuItem;