import LoginForm from "components/Forms/LoginForm";
import Logo from "components/Logo";
import { routes } from "components/Routes";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";

const PageLogin = () => {

    const navigate = useNavigate();
    authStore.resetStore();

    const onSubmit = async (data: { email: string, password: string }) => {
        try {
            await authStore.authenticate(data.email, data.password);
            navigate(routes.pageSynchronizeProducts);
        }
        catch (error) {
            toast('Invalid credentials', { type: 'error' });
        }
    }

    return (
        <div className="flex mt-32 justify-center">
            <div className="basis-1/4">
                <div className="mb-4">
                    <Logo />
                </div>
                <div>
                    <LoginForm onSubmit={onSubmit} />
                </div>
            </div>
        </div>
    )
}

export default PageLogin;