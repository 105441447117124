

import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { loginFormFields } from "./LoginFormFields";
import { UserInterface } from "types/UserInterface";
import { useNavigate } from "react-router-dom";
import { routes } from "components/Routes";

interface Props {
    defaultValues?: UserInterface,
    onSubmit: (data: UserInterface) => void,
}

const LoginForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const navigate = useNavigate();

    const validation = (yup: YupInterface) => {
        return {
            [loginFormFields.email.name]: loginFormFields.email.validation,
            [loginFormFields.password.name]: loginFormFields.password.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            formOptions={{ defaultValues: props.defaultValues }}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-1'>
                <div>
                    <Input name={loginFormFields.email.name} label={loginFormFields.email.label} />
                </div>
                <div>
                    <Input type="password" name={loginFormFields.password.name} label={loginFormFields.password.label} />
                </div>
            </div>
            <div className="grid grid-cols-2">
                <div>
                    <Button
                        type='submit'
                        label='Login'
                        appearance="primary"
                    />
                </div>
                <div className="text-right">
                    <button type="button" className="leading-10 link-dark" onClick={() => navigate(routes.pageResetPassword)}>Reset password</button>
                </div>
            </div>
        </Form>
    )
}


export default LoginForm;