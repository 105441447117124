import { action, makeObservable, observable } from "mobx";
import { AnalyzeAspect4ProductImageInterface } from "types/AnalyzeAspect4ProductImageInterface";
import { AnalyzedAspect4ProductImageInterface } from "types/AnalyzedAspect4ProductImageInterface";
import { Aspect4ProductImageInterface } from "types/Aspect4ProductImage";
import { BaseStore } from "./BaseStore";
import request from 'helpers/Request';

class Aspect4ProductImagesStore extends BaseStore {
    aspect4ProductImages: Aspect4ProductImageInterface[] | null = null;
    analyzedAspect4ProductImages: AnalyzedAspect4ProductImageInterface[] | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4ProductImages: observable,
            analyzedAspect4ProductImages: observable,

            resetStore: action,
            getAspect4ProductImages: action,
            setImagePositions: action,
            deleteImage: action,
            analyzeProductImages: action,
        })
    }

    resetStore = () => {
        this.aspect4ProductImages = null;
        this.analyzedAspect4ProductImages = null;
    }

    getAspect4ProductImages = (productGid: string) => {
        return this.get(`aspect4/internal/products/${productGid}/images`, 'aspect4ProductImages');
    }

    setImagePositions = (productGid: string, imagePositions: string[]) => {
        let data = {
            aspect4ProductImagesPositions: imagePositions
        }
        return this.put(`aspect4/internal/products/${productGid}/images`, data, null);
    }

    deleteImage = (productGid: string, productImageGid: string) => {
        return this.delete(
            `aspect4/internal/products/${productGid}/images/${productImageGid}`,
            productImageGid,
            this.aspect4ProductImages,
            'gid'
        );
    }

    purgeAspect4ProductImages = (aspect4ProductGid: string) => {
        // note: Using request.delete directly instead of BaseStore.delete
        return request.delete(`aspect4/internal/products/${aspect4ProductGid}/images`);
    }

    uploadAspect4ProductImage = (aspect4ProductGid: string, file: File) => {
        // note: Using request.post directly instead of BaseStore.post
        const formData = new FormData();
        formData.append("File", file);
        const url = `/aspect4/internal/products/${aspect4ProductGid}/images`;
        return request.post(url, formData);
    }

    analyzeProductImages = (productImages: AnalyzeAspect4ProductImageInterface[]) => {
        let data = {
            analyzeProductImages: productImages
        }
        return this.post(`aspect4/internal/products/images/analyze`, data, 'analyzedAspect4ProductImages', true);
    }
}

export const aspect4ProductImagesStore = new Aspect4ProductImagesStore();