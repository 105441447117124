import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { updateUserFormFields } from "./UpdateUserFormFields";
import { UserInterface } from "types/UserInterface";

interface Props {
    defaultValues?: UserInterface,
    onSubmit: (data: UserInterface) => void,
}

const UpdateUserForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [updateUserFormFields.fullName.name]: updateUserFormFields.fullName.validation,
            [updateUserFormFields.email.name]: updateUserFormFields.email.validation,
            [updateUserFormFields.verified.name]: updateUserFormFields.verified.validation,
            [updateUserFormFields.password.name]: updateUserFormFields.password.validation,
            [updateUserFormFields.confirmPassword.name]: updateUserFormFields.confirmPassword.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            formOptions={{ defaultValues: props.defaultValues }}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-2'>
                <div>
                    <Input name={updateUserFormFields.fullName.name} label={updateUserFormFields.fullName.label} description={updateUserFormFields.fullName.description} />
                </div>
                <div>
                    <Input name={updateUserFormFields.email.name} label={updateUserFormFields.email.label} description={updateUserFormFields.email.description} />
                </div>
                <div>
                    <Input type="password" name={updateUserFormFields.password.name} label={updateUserFormFields.password.label} description={updateUserFormFields.password.description} />
                </div>
                <div>
                    <Input type="password" name={updateUserFormFields.confirmPassword.name} label={updateUserFormFields.confirmPassword.label} description={updateUserFormFields.confirmPassword.description} />
                </div>
                <div>
                    <Input name={updateUserFormFields.verified.name} label={updateUserFormFields.verified.label} description={updateUserFormFields.verified.description} type="checkbox" />
                </div>
            </div>
            {
                <Button
                    type='submit'
                    label='Save'
                    appearance="primary"
                />
            }
        </Form>
    )
}

export default UpdateUserForm;