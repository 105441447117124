import Pagination from "components/Pagination";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { analyzeLogStore } from "stores/AnalyzeLogStore";
import { AnalyzeLogInterface } from "types/AnalyzeLogInterface";

const PageLogsAnalyze = observer(() => {

    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        analyzeLogStore.getAnalyzeLogs(page > 1 ? page : 1);

        return () => {
            analyzeLogStore.resetStore();
        }
    }, [searchParams])

    return (
        <div>
            <h1>Analyze Log</h1>
            {
                analyzeLogStore.analyzeLogs?.items &&
                analyzeLogStore.analyzeLogs?.items.length > 0 &&
                <Table
                    data={analyzeLogStore.analyzeLogs.items}
                    head={[
                        { label: 'Gid', sortProperty: '' },
                        { label: 'Task', sortProperty: '' },
                        { label: 'Entity', sortProperty: '' },
                        { label: 'Entity Id', sortProperty: '' },
                        { label: 'Message', sortProperty: '' },
                        { label: 'Date', sortProperty: '' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    renderRow={(analyzeLog: AnalyzeLogInterface) => {
                        return (
                            <>
                            <Cell>{analyzeLog.gid}</Cell>
                                <Cell>{analyzeLog.task}</Cell>
                                <Cell>{analyzeLog.entity}</Cell>
                                <Cell>{analyzeLog.entityId}</Cell>
                                <Cell>{analyzeLog.message}</Cell>
                                <Cell width={150}>{moment(analyzeLog.createdOn).format('YYYY-MM-DD HH:mm')}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                analyzeLogStore.analyzeLogs?.pagination &&
                <Pagination pagination={analyzeLogStore.analyzeLogs.pagination} />
            }
        </div>
    )
})

export default PageLogsAnalyze;