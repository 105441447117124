import { action, makeObservable, observable } from "mobx";
import { Aspect4ProductVariantInterface } from "types/Aspect4ProductVariantInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class Aspect4ProductVariantStore extends BaseStore {
    aspect4ProductVariants: PagingResultInterface<Aspect4ProductVariantInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4ProductVariants: observable,

            resetStore: action,
            getAspect4ProductVariants: action
        })
    }

    resetStore = () => {
        this.aspect4ProductVariants = null;
    }

    getAspect4ProductVariants = (pageNumber: number = 1, search: string | null) => {
        const url = `aspect4/internal/product-variants?` + (search != null ? `&search=${search}` : ``);
        return this.getPaged(url, this.aspect4ProductVariants, 'aspect4ProductVariants', pageNumber)
    }
}

export const aspect4ProductVariantStore = new Aspect4ProductVariantStore();