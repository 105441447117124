const EnvironmentBanner = () => {
    if (process.env.REACT_APP_SHOW_TEST_ENV_BANNER === "true") {
        return (
            <div className="p-2 text-center bg-red-500 border-b border-white text-white">
                NB: This is the test environment. Visit{" "}
                <a href="http://munthe-web.signifly.io" className="underline">
                    http://munthe-web.signifly.io
                </a>{" "}
                to access the production environment.
            </div>
        );
    }

    return null;
};

export default EnvironmentBanner;
