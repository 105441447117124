class FormFields {
    public extractDefaultValues(extractFromObject?: any): any | undefined {
        let defaultValues = { ...extractFromObject }
        if (defaultValues == null) {
            return undefined;
        }

        const validProperties = Object.getOwnPropertyNames(this);

        Object.getOwnPropertyNames(defaultValues).forEach(propName => {
            if (validProperties.indexOf(propName) === -1) {
                delete defaultValues[propName];
            }
        })

        return defaultValues;
    }
}

export default FormFields;