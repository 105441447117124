import { FormFieldInterface } from "types/FormFieldInterface";
import FormFields from "../Form/FormFields";

class Aspect4ProductFormFields extends FormFields{
    public gid: FormFieldInterface = {
        name: 'gid',
        label: 'gid',
    }

    public itemColorText: FormFieldInterface = {
        name: 'itemColorText',
        label: 'itemColorText',
    }

    public itemStyle: FormFieldInterface = {
        name: 'itemStyle',
        label: 'itemStyle',
    }

    public itemQuality: FormFieldInterface = {
        name: 'itemQuality',
        label: 'itemQuality',
    }

    public picStyle: FormFieldInterface = {
        name: 'picStyle',
        label: 'picStyle',
    }

    public picColor: FormFieldInterface = {
        name: 'picColor',
        label: 'picColor',
    }

    public picColor9: FormFieldInterface = {
        name: 'picColor9',
        label: 'picColor9',
    }

    public brandText: FormFieldInterface = {
        name: 'brandText',
        label: 'brandText',
    }

    public picColor10: FormFieldInterface = {
        name: 'picColor10',
        label: 'picColor10',
    }

    public itemGroup: FormFieldInterface = {
        name: 'itemGroup',
        label: 'itemGroup',
    }

    public itemBrand: FormFieldInterface = {
        name: 'itemBrand',
        label: 'itemBrand',
    }

    public brand: FormFieldInterface = {
        name: 'brand',
        label: 'brand',
    }

    public itemGroupText: FormFieldInterface = {
        name: 'itemGroupText',
        label: 'itemGroupText',
    }

    public new: FormFieldInterface = {
        name: 'new',
        label: 'new',
    }

    public item: FormFieldInterface = {
        name: 'item',
        label: 'item',
    }

    public itemColor: FormFieldInterface = {
        name: 'itemColor',
        label: 'itemColor',
    }

    public picColor4: FormFieldInterface = {
        name: 'picColor4',
        label: 'picColor4',
    }

    public picColor3: FormFieldInterface = {
        name: 'picColor3',
        label: 'picColor3',
    }

    public picColor2: FormFieldInterface = {
        name: 'picColor2',
        label: 'picColor2',
    }

    public collection: FormFieldInterface = {
        name: 'collection',
        label: 'collection',
    }

    public picColor8: FormFieldInterface = {
        name: 'picColor8',
        label: 'picColor8',
    }

    public image2: FormFieldInterface = {
        name: 'image2',
        label: 'image2',
    }

    public picColor7: FormFieldInterface = {
        name: 'picColor7',
        label: 'picColor7',
    }

    public picColor6: FormFieldInterface = {
        name: 'picColor6',
        label: 'picColor6',
    }

    public picColor5: FormFieldInterface = {
        name: 'picColor5',
        label: 'picColor5',
    }

    public compText: FormFieldInterface = {
        name: 'compText',
        label: 'compText',
    }

    public activ: FormFieldInterface = {
        name: 'activ',
        label: 'activ',
    }

    public itemText: FormFieldInterface = {
        name: 'itemText',
        label: 'itemText',
    }

    public compCode: FormFieldInterface = {
        name: 'compCode',
        label: 'compCode',
    }

    public collectionName: FormFieldInterface = {
        name: 'collectionName',
        label: 'collectionName',
    }

    public fitting: FormFieldInterface = {
        name: 'fitting',
        label: 'fitting',
    }

    public imagesModifiedOn: FormFieldInterface = {
        name: 'imagesModifiedOn',
        label: 'imagesModifiedOn',
    }
}

export const aspect4ProductFormFields = new Aspect4ProductFormFields();