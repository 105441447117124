import { action, makeObservable, observable } from "mobx";
import { Aspect4ProductVariantInterface } from "types/Aspect4ProductVariantInterface";
import { BaseStore } from "./BaseStore";

class Aspect4ProductVariantDetailsStore extends BaseStore {
    aspect4ProductVariant: Aspect4ProductVariantInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4ProductVariant: observable,

            resetStore: action,
            getAspect4ProductVariant: action
        })
    }

    resetStore = () => {
        this.aspect4ProductVariant = null;
    }

    getAspect4ProductVariant = (gid: string) => {
        return this.get(`aspect4/internal/product-variants/${gid}`, 'aspect4ProductVariant');
    }
}

export const aspect4ProductVariantDetailsStore = new Aspect4ProductVariantDetailsStore();