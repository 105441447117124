import { action, makeObservable, observable } from "mobx";
import { ShopifyProductVariantInterface } from "types/ShopifyProductVariantInterface";
import { BaseStore } from "./BaseStore";

class ShopifyProductVariantDetailsStore extends BaseStore {
    shopifyProductVariant: ShopifyProductVariantInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyProductVariant: observable,

            resetStore: action,
            approveTakeAction: action,
            setTakeAction: action,
        })
    }

    resetStore = () => {
        this.shopifyProductVariant = null;
    }

    getShopifyProductVariant = (gid: string) => {
        return this.get(`shopify/internal/product-variants/${gid}`, 'shopifyProductVariant');
    }

    approveTakeAction = (gid: string) => {
        const data = {
            shopifyProductVariantGuids: [gid]
        };
        return this.put(`shopify/internal/product-variants/approve-take-action`, data, null);
    }

    setTakeAction = (gid: string, takeAction: string|null) => {
        const data = {
            shopifyProductVariantGuids: [gid],
            takeAction: takeAction
        };
        return this.put(`shopify/internal/product-variants/set-take-action`, data, null);
    }
}

export const shopifyProductVariantDetailsStore = new ShopifyProductVariantDetailsStore();