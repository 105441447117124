import ShopifyProductVariantForm from "components/Forms/ShopifyProductVariantForm";
import { shopifyProductVariantFormFields } from "components/Forms/ShopifyProductVariantForm/ShopifyProductVariantFormFields";
import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { shopifyProductVariantDetailsStore } from "stores/ShopifyProductVariantDetailsStore";

const PageSynchronizeProductVariantDetails = observer(() => {

    const { gid } = useParams();
    const navigate = useNavigate();
    const [showDebugData, setShowDebugData] = useState<boolean>(false);

    useEffect(() => {
        if (gid) {
            shopifyProductVariantDetailsStore.getShopifyProductVariant(gid);
        }

        return () => {
            shopifyProductVariantDetailsStore.resetStore();
        }
    }, [gid])

    const shopifyProductVariant = shopifyProductVariantDetailsStore.shopifyProductVariant;

    const defaultValues = shopifyProductVariantFormFields.extractDefaultValues(shopifyProductVariant);

    const disableApproveAndIgnoreButtons = () => {
        return shopifyProductVariant?.takeAction === null ||
            shopifyProductVariant?.takeAction === 'ignore' ||
            shopifyProductVariant?.takeAction === 'awaits' ||
            shopifyProductVariant?.takeActionApprovedOn !== null;
    }

    const approveSyncAction = async () => {
        await shopifyProductVariantDetailsStore.approveTakeAction(gid!);
        toast("Variant synchronization approved. Synchronization will run within an hour.", { type: 'success' });
        shopifyProductVariantDetailsStore.getShopifyProductVariant(gid!);
    }

    const setTakeAction = async (takeAction: string | null) => {
        await shopifyProductVariantDetailsStore.setTakeAction(gid!, takeAction);
        if (takeAction === 'ignore') {
            toast("Variant synchronization will be ignored until you reactivate 'Analyze'.", { type: 'success' });
        } else {
            toast("Sync. action has been resat. Variant will be re-analyzed within an hour.", { type: 'success' });
        }
        shopifyProductVariantDetailsStore.getShopifyProductVariant(gid!);
    }

    const markAsChange = (valueA?: any, valueB?: any) => {
        return (shopifyProductVariant?.takeAction === 'create' || valueA !== valueB ? 'text-red-500 ' : '') + ' align-text-top';
    }

    const gotoAspectProductVariant = () => {
        navigate(generatePath(routes.pageAspect4ProductVariantDetails, { gid: shopifyProductVariant?.aspect4ProductVariantGid }))
    }

    const gotoShopifyProduct = () => {
        navigate(generatePath(routes.pageSynchronizeProductDetails, { gid: shopifyProductVariant?.shopifyProductGid }))
    }

    if (!shopifyProductVariant)
        return null;

    return (
        <div>
            {shopifyProductVariant?.takeActionApprovedOn != null && <div className="float-right leading-10 text-green-600 font-bold ">Sync. pending</div>}
            {shopifyProductVariant?.takeActionApprovedOn == null && <div className="float-right">
                <button
                    type="button"
                    className="btn-primary mb-8 float-right"
                    disabled={disableApproveAndIgnoreButtons()}
                    onClick={() => approveSyncAction()}
                >
                    Approve Sync.
                </button>

                {
                    shopifyProductVariant?.takeAction === 'ignore' && <button
                        type="button"
                        className="btn-secondary mb-8 float-right mr-4"
                        onClick={() => setTakeAction(null)}
                    >
                        Analyze
                    </button>
                }

                {
                    shopifyProductVariant?.takeAction !== 'ignore' && <button
                        type="button"
                        className="btn-secondary mb-8 float-right mr-4"
                        disabled={disableApproveAndIgnoreButtons()}
                        onClick={() => setTakeAction('ignore')}
                    >
                        Ignore sync.
                    </button>
                }
            </div>
            }


            <h1>Shopify Product Variant Details</h1>
            <div className="py-4 mb-4">Properties and values marked in <span className="text-red-500">red</span> in the table below are the data that differ from Aspect4 and Shopify Shop.</div>
            <div className="py-4 mb-4 grid grid-cols-2 w-2/6">
                <strong>Product:</strong> <div>{shopifyProductVariant.aspect4ProductVariant?.itemText} {shopifyProductVariant.aspect4ProductVariant?.colourText}</div>
                <strong>Sync. Action:</strong> <div>{shopifyProductVariant.takeAction}</div>
                <strong>Shopify Shop:</strong> <div>{shopifyProductVariant.shopifyShop?.name}</div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl">
                <table className="align-text-top w-full" cellPadding={10} cellSpacing={10}>
                    <thead>
                        <tr>
                            <td className="font-bold w-2/12"></td>
                            <td className="font-bold w-4/12">Shopify</td>
                            <td className="font-bold w-4/12">From Aspect4</td>
                            <td className="font-bold w-2/12">Synchronize</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={markAsChange(shopifyProductVariant?.title, shopifyProductVariant?.synchronizeProductVariant?.title)}>
                            <td className="font-bold">Title</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.title}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.title}</td>
                            <td className="w-2/12">{shopifyProductVariant?.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.sku, shopifyProductVariant?.synchronizeProductVariant?.sku)}>
                            <td className="font-bold">SKU</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.sku}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.sku}</td>
                            <td className="w-2/12">{shopifyProductVariant?.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.barcode, shopifyProductVariant?.synchronizeProductVariant?.barcode)}>
                            <td className="font-bold">Barcode</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.barcode}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.barcode}</td>
                            <td className="w-2/12">{shopifyProductVariant?.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.price, shopifyProductVariant?.synchronizeProductVariant?.price)}>
                            <td className="font-bold">Price</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.price?.toFixed(2)}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.price?.toFixed(2)}</td>
                            <td>yes</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.option1, shopifyProductVariant?.synchronizeProductVariant?.option1)}>
                            <td className="font-bold">Option1</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.option1}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.option1}</td>
                            <td className="w-2/12">{shopifyProductVariant?.takeAction === 'create' ? 'yes' : 'no'}</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.fulfillmentService, shopifyProductVariant?.synchronizeProductVariant?.fulfillmentService)}>
                            <td className="font-bold">Fullfillment Service</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.fulfillmentService}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.fulfillmentService}</td>
                            <td>yes</td>
                        </tr>
                        <tr className={markAsChange(shopifyProductVariant?.inventoryManagement, shopifyProductVariant?.synchronizeProductVariant?.inventoryManagement)}>
                            <td className="font-bold">Inventory Management</td>
                            <td>{shopifyProductVariant?.takeAction === 'create' ? '' : shopifyProductVariant?.inventoryManagement}</td>
                            <td>{shopifyProductVariant?.synchronizeProductVariant?.inventoryManagement}</td>
                            <td>yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="clear-left mt-4">
                <button className="text-xs" onClick={() => setShowDebugData(!showDebugData)}>{showDebugData ? 'Hide Debug' : 'Debug'}</button>
            </div>
            {
                showDebugData && <div>
                    {
                        shopifyProductVariant?.shopifyProductGid && <button
                            className="my-8 mr-4 btn bg-blue-500"
                            type="button"
                            onClick={() => gotoShopifyProduct()}
                        >
                            Goto Shopify Product
                        </button>
                    }
                    {
                        shopifyProductVariant?.aspect4ProductVariantGid && <button
                            className="my-8 btn bg-blue-500"
                            type="button"
                            onClick={() => gotoAspectProductVariant()}
                        >
                            Goto Aspect4 Product Variant
                        </button>
                    }
                    {
                        !shopifyProductVariant?.aspect4ProductVariantGid && <span>No related Aspect4 Product Variant found</span>
                    }
                    <ShopifyProductVariantForm defaultValues={defaultValues} onSubmit={() => { }} />
                </div>
            }

        </div >

    )
})

export default PageSynchronizeProductVariantDetails;