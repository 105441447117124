import { MiniProgressSpinner } from "components/MiniProgressSpinner";
import request from "helpers/Request";
import { isShopifyImageValid } from "helpers/ShopifyImageValidation";
import { useState } from "react";
import { toast } from "react-toastify";

interface Props {
    uploadPostUrl: string,
    uploadCallback: () => void
}
const FileUpload = (props: Props) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadCount, setUploadCount] = useState<number>(0);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    const onFileSelect = async (event: any) => {
        const resetFile = () => {
            event.target.value = null;
            setSelectedFile(null);
        }

        if (event.target.files[0]) {
            // Validate image size
            if (await isShopifyImageValid(event.target.files[0]) === false) {
                toast('File size exceeded the limits of 4472 x 4472', { type: 'error' });
                resetFile();
                return;
            }
            setSelectedFile(event.target.files[0])
        } else {
            resetFile();
            return;
        }
    }

    const handleUpload = async (event: any) => {
        if (!selectedFile) return;

        setUploadInProgress(true)
        event.preventDefault()
        const formData = new FormData();
        formData.append("File", selectedFile);
        try {

            await request.post(props.uploadPostUrl, formData);
            props.uploadCallback();
        } catch (error) {
            console.log(error)
        }
        setUploadCount(uploadCount + 1);
        setSelectedFile(null);
        setUploadInProgress(false)
    }

    return <form onSubmit={handleUpload}>
        <input type="file" accept="image/*" id="file" name="file" onChange={onFileSelect} key={uploadCount} />
        <button className="btn btn-primary mb-8" type="submit" disabled={selectedFile == null || uploadInProgress}>
            {uploadInProgress && <MiniProgressSpinner />} Upload
        </button>
    </form>

}

export default FileUpload;