import { FormFieldInterface } from "types/FormFieldInterface";
import * as yup from "yup";
import FormFields from "../Form/FormFields";

class CreateUserFormFields extends FormFields {
    public fullName: FormFieldInterface = {
        name: 'fullName',
        label: 'Full name',
        validation: yup.string().required('Required')
    }

    public email: FormFieldInterface = {
        name: 'email',
        label: 'Email',
        validation: yup.string().required('Required').email('Email is required')
    }

    public verified: FormFieldInterface = {
        name: 'verified',
        label: 'Verified',
        validation: yup.bool(),
    }

    public password: FormFieldInterface = {
        name: 'password',
        label: 'Password',
        validation: yup.string().required('Required').min(6, 'Password must be at least 6 characters')
    }

    public confirmPassword: FormFieldInterface = {
        name: 'confirmPassword',
        label: 'Confirm password',
        validation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    }
}
export const createUserFormFields = new CreateUserFormFields();