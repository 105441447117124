import CreateUserForm from "components/Forms/CreateUserForm";
import UpdateUserForm from "components/Forms/UpdateUserForm";
import { updateUserFormFields } from "components/Forms/UpdateUserForm/UpdateUserFormFields";

import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { userDetailsStore } from "stores/UserDetailsStore";
import { UserInterface } from "types/UserInterface";

const PageUserDetails = observer(() => {

    const navigate = useNavigate();
    const { gid } = useParams();

    useEffect(() => {
        if (gid) {
            userDetailsStore.getUser(gid);
        }

        return () => {
            userDetailsStore.resetStore();
        }
    }, [gid])

    const onSubmit = async (data: UserInterface) => {
        try {
            if (userDetailsStore.user?.gid) {
                data.gid = userDetailsStore.user?.gid;
                await userDetailsStore.updateUser(data)
            } else {
                await userDetailsStore.createUser(data)
            }
            navigate(generatePath(routes.pageUsers))
            toast('User saved successfully', { type: 'success' })
        } catch (err) {
            toast('An error occured saving User', { type: 'error' });
        }
    }

    const defaultValues = userDetailsStore.user
        ? updateUserFormFields.extractDefaultValues(userDetailsStore.user)
        : undefined;

    if (gid) {
        return (
            <div>
                <h1>User</h1>
                <UpdateUserForm onSubmit={onSubmit} defaultValues={defaultValues} />
            </div>
        )
    }

    return (
        <div>
            <h1>New User</h1>
            <CreateUserForm onSubmit={onSubmit} defaultValues={defaultValues} />
        </div>
    )
})

export default PageUserDetails;