import { Table as MaterialUITable, TableHead, TableBody, TableContainer, TableRow } from '@material-ui/core';
import { useState } from 'react';
import Cell from './Cell';
import SortLabel from './SortLabel';
import _ from 'lodash';

interface HeadInterface {
    label: string,
    sortProperty?: string,
    rowFormat?: (row: any) => void
}

interface Props {
    data: Array<any>,
    head: Array<HeadInterface>,
    defaultSortProperty: string,
    defaultSortDirection: 'asc' | 'desc',
    renderRow: (item: any) => React.ReactNode,
    onRowClicked?: (item: any) => void,
    onRowChecked?: (item: any) => any,
    selectAll?: (items: any) => any,
}

const Table = (props: Props) => {
    const [orderFunc, setOrderFunc] = useState<Function | null>(null);
    const [orderBy, setOrderBy] = useState(props.defaultSortProperty);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>(props.defaultSortDirection);
    const [selection, setSelection] = useState<any[]>([]);

    const onSort = (property: string, orderFunction?: (row: any) => void) => {
        if (orderFunction) {
            setOrderFunc(orderFunction);
        }
        else {
            setOrderFunc(null);
        }

        if (orderBy === property) {
            orderDirection === 'asc' ? setOrderDirection('desc') : setOrderDirection('asc')
        }
        else {
            setOrderBy(property);
            setOrderDirection('asc');
        }
    }

    const data = _.orderBy(props.data, orderFunc ? (val) => orderFunc(val) : orderBy, orderDirection);

    const selectAll = () => {

        const newSelection = selection.length === props.data.length ? [] : [...props.data];

        setSelection([...newSelection]);

        if (props.selectAll !== undefined) {
            props.selectAll(newSelection);
        }
    }

    const onRowChecked = (item: any) => {

        const newSelection = selection.includes(item)
            ? selection.filter(selectedItem => selectedItem !== item)
            : [...selection, item];

        setSelection(prevSelection => [...newSelection]);

        if (props.onRowChecked !== undefined) {
            props.onRowChecked(newSelection);
        }
    }

    return (
        <TableContainer>
            <MaterialUITable>
                <TableHead>
                    <TableRow>
                        {
                            props.selectAll &&
                            <Cell>
                                <input
                                    type="checkbox"
                                    onChange={selectAll}
                                    checked={selection.length === props.data.length}
                                    className="cursor-pointer"
                                />
                            </Cell>
                        }
                        {
                            props.head.map((h, i) => {
                                return (
                                    <Cell key={i}>
                                        {h.sortProperty && <SortLabel
                                            label={h.label}
                                            sortProperty={h.sortProperty}
                                            activeProperty={orderBy}
                                            direction={orderDirection}
                                            onSort={onSort}
                                            rowFormat={h.rowFormat}
                                        />}
                                        {!h.sortProperty && <span>{h.label}</span>}
                                    </Cell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((item, i) => {
                            return (
                                <TableRow key={i} onClick={() => props.onRowClicked ? props.onRowClicked(item) : undefined} >
                                    {
                                        (props.selectAll || props.onRowChecked) &&
                                        <Cell onClick={(e) => { e.stopPropagation() }}>
                                            <input
                                                className="cursor-pointer"
                                                type="checkbox"
                                                onChange={() => props.onRowChecked !== undefined
                                                    ? onRowChecked(item)
                                                    : null
                                                }
                                                checked={selection.includes(item)}
                                            />
                                        </Cell>
                                    }
                                    {props.renderRow(item)}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </MaterialUITable>
        </TableContainer>
    )
}

export default Table;