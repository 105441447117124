import { routes } from "components/Routes";
import { useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";

const Avatar = () => {
    const navigate = useNavigate();

    let name = authStore.user?.fullName ?? '';

    return (
        <div className="basis-4/12 relative">
            <div className="float-right bg-black text-white rounded-full font-bold h-10 w-10 flex justify-center items-center">{name.charAt(0)}</div>
            <div className="inline-block text-sm float-right leading-10 mr-2">
                <button className="mr-2" onClick={() => navigate(routes.pageLogin)}>Logout</button> |
                <span className="ml-2">{name}</span>
            </div>
        </div>
    )
}

export default Avatar;