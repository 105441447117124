import { action, makeObservable, observable } from "mobx";
import { PagingResultInterface } from "types/PagingResultInterface";
import { ShopifyProductVariantInterface } from "types/ShopifyProductVariantInterface";
import { BaseStore } from "./BaseStore";

class ShopifyProductVariantStore extends BaseStore {
    shopifyProductVariants: PagingResultInterface<ShopifyProductVariantInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyProductVariants: observable,
            
            resetStore: action,
            getShopifyProductVariants: action,
            approveAll: observable,
            approveSelection: observable,
        })
    }

    resetStore = () => {
        this.shopifyProductVariants = null;
    }

    getShopifyProductVariants = (pageNumber: number, takeAction: string | null = null, search: string | null = null) => {
        const url = `shopify/internal/product-variants?`
            + (takeAction != null ? `&takeAction=${takeAction}` : ``)
            + (search != null ? `&search=${search}` : ``);

        return this.getPaged(url, this.shopifyProductVariants, 'shopifyProductVariants', pageNumber)
    }

    approveAll = () => {
        return this.put('shopify/internal/product-variants/approve-all-take-action', {}, null);
    }

    approveSelection = (gids: string[]) => {
        const data = {
            shopifyProductVariantGuids: gids
        };
        return this.put(`shopify/internal/product-variants/approve-take-action`, data, null);
    }
}

export const shopifyProductVariantStore = new ShopifyProductVariantStore();