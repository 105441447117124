import EnvironmentBanner from "components/EnvironmentBanner";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

const AccessOpenContainer = observer(() => {
    return (
        <div className="">
            <EnvironmentBanner />
            <Outlet />
        </div>
    );
});

export default AccessOpenContainer;
