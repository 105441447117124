import Pagination from "components/Pagination";
import { routes } from "components/Routes";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { shopifyShopsStore } from "stores/ShopifyShopsStore";
import { ShopifyShopInterface } from "types/ShopifyShopInterface";

const PageShopifyShops = observer(() => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        shopifyShopsStore.getShopifyShops(page > 1 ? page : 1);

        return () => {
            shopifyShopsStore.resetStore();
        }
    }, [searchParams])

    return (
        <div>

            <h1>Shopify Shops</h1>
            <button type="button" className="btn-primary mb-4" onClick={() => navigate(routes.pageCreateShopifyShop)}>New</button>
            {
                shopifyShopsStore.shopifyShops?.items &&
                shopifyShopsStore.shopifyShops?.items.length > 0 &&
                <Table
                    data={shopifyShopsStore.shopifyShops.items}
                    head={[
                        { label: 'Gid', sortProperty: '' },
                        { label: 'Name', sortProperty: '' },
                        { label: 'Language Code', sortProperty: '' },
                        { label: 'Currency', sortProperty: '' },
                        { label: 'Price List', sortProperty: '' },
                        { label: 'Ready For Analyze', sortProperty: '' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    onRowClicked={(shopifyShop: ShopifyShopInterface) => navigate(generatePath(routes.pageEditShopifyShop, { gid: shopifyShop.gid }))}
                    renderRow={(shopifyShop: ShopifyShopInterface) => {
                        return (
                            <>
                                <Cell>{shopifyShop.gid}</Cell>
                                <Cell>{shopifyShop.name}</Cell>
                                <Cell>{shopifyShop.languageCode}</Cell>
                                <Cell>{shopifyShop.currency}</Cell>
                                <Cell>{shopifyShop.priceList}</Cell>
                                <Cell>{shopifyShop.readyForAnalyze ? 'true' : 'false'}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                shopifyShopsStore.shopifyShops?.pagination &&
                <Pagination pagination={shopifyShopsStore.shopifyShops.pagination} />
            }
        </div>
    )
})

export default PageShopifyShops;