import { FormFieldInterface } from "types/FormFieldInterface";
import * as yup from "yup";
import FormFields from "../Form/FormFields";

class LoginFormFields extends FormFields {
    public email: FormFieldInterface = {
        name: 'email',
        label: 'Email',
        validation: yup.string().required('Required').email('Email is required')
    }
    public password: FormFieldInterface = {
        name: 'password',
        label: 'Password',
        validation: yup.string().required('Required')
    }
}
export const loginFormFields = new LoginFormFields();