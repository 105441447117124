import Pagination from "components/Pagination";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { stockStore } from "stores/StockStore";
import { StockInterface } from "types/StockInterface";

const PageInventoryReceived = observer(() => {

    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        stockStore.getStocks(page > 1 ? page : 1);

        return () => {
            stockStore.resetStore();
        }
    }, [searchParams])


    return (
        <div>
            <h1>Inventory Received</h1>
            {
                stockStore.stocks?.items &&
                stockStore.stocks?.items.length > 0 &&
                <Table
                    data={stockStore.stocks.items}
                    head={[
                        { label: 'Sku', sortProperty: '' },
                        { label: 'Quantity', sortProperty: '' },
                    ]}
                    defaultSortProperty={"sku"}
                    defaultSortDirection={"desc"}
                    renderRow={(stock: StockInterface) => {
                        return (
                            <>
                                <Cell>{stock.sku}</Cell>
                                <Cell>{stock.quantity}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                stockStore.stocks?.pagination &&
                <Pagination pagination={stockStore.stocks.pagination} />
            }
        </div>
    )
})

export default PageInventoryReceived;