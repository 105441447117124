import Pagination from "components/Pagination";
import { routes } from "components/Routes";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { RoleEnumToString } from "helpers/Role";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { usersStore } from "stores/UsersStore";
import { UserInterface } from "types/UserInterface";

const PageUsers = observer(() => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        usersStore.getUsers(page > 1 ? page : 1);

        return () => {
            usersStore.resetStore();
        }
    }, [searchParams])

    return (
        <div>

            <h1>Users</h1>
            <button type="button" className="btn-primary mb-4" onClick={() => navigate(routes.pageCreateUser)}>New</button>
            {
                usersStore.users?.items &&
                usersStore.users?.items.length > 0 &&
                <Table
                    data={usersStore.users.items}
                    head={[
                        { label: 'Gid' },
                        { label: 'Full Name' },
                        { label: 'Email' },
                        { label: 'Role' },
                        { label: 'Verified' },
                    ]}
                    defaultSortProperty={""}
                    defaultSortDirection={"desc"}
                    onRowClicked={(user: UserInterface) => navigate(generatePath(routes.pageEditUser, { gid: user.gid }))}
                    renderRow={(user: UserInterface) => {
                        return (
                            <>
                                <Cell>{user.gid}</Cell>
                                <Cell>{user.fullName}</Cell>
                                <Cell>{user.email}</Cell>
                                <Cell>{RoleEnumToString(user.role)}</Cell>
                                <Cell>{user.verified ? 'true' : 'false'}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                usersStore.users?.pagination &&
                <Pagination pagination={usersStore.users.pagination} />
            }
        </div>
    )
})

export default PageUsers;