import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Select, { OptionInterface } from "components/Forms/Select";
import Pagination from "components/Pagination";
import { routes } from "components/Routes";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { shopifyProductVariantStore } from "stores/ShopifyProductVariantStore";
import { ShopifyProductVariantInterface } from "types/ShopifyProductVariantInterface";
import { MiniProgressSpinner } from "components/MiniProgressSpinner";

const PageSynchronizeProductVariants = observer(() => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') ?? '1'));
    const [approveInProgress, setApproveInProgress] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string | null>(searchParams.get('search'));
    const [actionQuery, setActionQuery] = useState<string | null>(searchParams.get('takeAction'));
    const [selection, setSelection] = useState<ShopifyProductVariantInterface[]>([]);

    // when searchParams changes
    useEffect(() => {
        // pagination
        let pageQuery = searchParams.get('page') ?? '1';
        setPage(parseInt(pageQuery));

        // filters
        setSearchQuery(searchParams.get('search'));
        setActionQuery(searchParams.get('takeAction'));
        setSelection([]);

    }, [searchParams])

    // when page, search or action states changes
    useEffect(() => {
        shopifyProductVariantStore.getShopifyProductVariants(page > 1 ? page : 1, actionQuery, searchQuery);
    }, [page, searchQuery, actionQuery])

    // resetter
    useEffect(() => {
        return () => {
            shopifyProductVariantStore.resetStore();
        }
    }, [])

    const shopifyProductVariants = shopifyProductVariantStore.shopifyProductVariants?.items;

    const disableApproveSelection = () => {
        return (actionQuery !== null && actionQuery === 'ignore') // filtered by take action = ignore
            || (!shopifyProductVariants || shopifyProductVariants.length === 0) // no shopify product variants
            || approveInProgress // approve already in progress
            || selection.length === 0; // no selection
    }

    const approveSelection = async () => {
        if (!window.confirm(`Please confirm:\n\nALL selected product variants will be synchronized.\n\nWarning: This action cannot be undone!`)) return;

        setApproveInProgress(true);
        await shopifyProductVariantStore.approveSelection(selection.map((item: ShopifyProductVariantInterface) => item.gid));

        // reset after approve selection
        shopifyProductVariantStore.resetStore();
        shopifyProductVariantStore.getShopifyProductVariants(1, actionQuery, searchQuery);
        setSelection([]);
        setApproveInProgress(false);
    }

    const filter = (data: { search: string, takeAction: string }) => {
        let url = location.pathname + '?page=1';
        if (data.search) {
            url += '&search=' + data.search;
        }
        if (data.takeAction) {
            url += '&takeAction=' + data.takeAction;
        }

        const dataSearch = (typeof data.search != 'undefined' && data.search ? data.search : null);
        const dataTakeAction = (typeof data.takeAction != 'undefined' && data.takeAction ? data.takeAction : null);

        // only navigate if query is different from current
        // (navigate doesn't trigger resetStore because it's the same path)
        if (dataSearch !== searchQuery || dataTakeAction !== actionQuery) {
            shopifyProductVariantStore.resetStore();
            navigate(url);
        }
    }

    const takeActionOptions: Array<OptionInterface> = [
        {
            id: 'create',
            value: 'create'
        },
        {
            id: 'update',
            value: 'update'
        },
        {
            id: 'delete',
            value: 'delete'
        },
        {
            id: 'ignore',
            value: 'ignore'
        }
    ]

    return (
        <div>
            <h1>Synchronize Product Variants</h1>
            <Form onSubmit={(data) => filter(data)} >
                <div className="mb-8">
                    <Input
                        placeholder="Lookup Title, Product, Product Id, SKU or Id"
                        name="search"
                        className="w-2/6 float-left"
                        defaultValue={searchQuery ?? undefined}
                    />
                    <Select
                        options={takeActionOptions}
                        name="takeAction"
                        className="w-1/6 float-left ml-4"
                        defaultValue={actionQuery ?? undefined}
                        placeholder="Sync. action (all)"
                    />
                    <button type="submit" className="ml-4 btn-primary">Filter</button>
                    <button type="button" className="btn-primary float-right" disabled={disableApproveSelection()} onClick={approveSelection}>
                        {
                            approveInProgress
                                ? <><MiniProgressSpinner /> <span>Approving...</span></>
                                : `Approve (${selection.length})`
                        }
                    </button>
                </div>

            </Form>
            {
                shopifyProductVariants &&
                shopifyProductVariants.length > 0 &&
                <Table
                    data={shopifyProductVariants}
                    head={[
                        { label: 'Title', sortProperty: '' },
                        { label: 'Product', sortProperty: '' },
                        { label: 'Product Id (Shopify)', sortProperty: '' },
                        { label: 'SKU', sortProperty: '' },
                        { label: 'Price', sortProperty: '' },
                        { label: 'Id (Shopify)', sortProperty: '' },
                        { label: 'Shop', sortProperty: '' },
                        { label: 'Sync. action', sortProperty: '' },
                    ]}
                    defaultSortProperty={"productId"}
                    defaultSortDirection={"desc"}
                    selectAll={(selection) => setSelection([...selection])}
                    onRowChecked={(selection) => setSelection([...selection])}
                    onRowClicked={(shopifyProductVariant: ShopifyProductVariantInterface) => navigate(generatePath(routes.pageSynchronizeProductVariantDetails, { gid: shopifyProductVariant.gid }))}
                    renderRow={(shopifyProductVariant: ShopifyProductVariantInterface) => {
                        return (
                            <>
                                <Cell>{shopifyProductVariant.title}</Cell>
                                <Cell>{shopifyProductVariant.aspect4ProductVariant?.itemText} ({shopifyProductVariant.aspect4ProductVariant?.colourText})</Cell>
                                <Cell>{shopifyProductVariant.productId}</Cell>
                                <Cell>{shopifyProductVariant.sku}</Cell>
                                <Cell>{shopifyProductVariant.price?.toFixed(2)}</Cell>
                                <Cell>{shopifyProductVariant.id}</Cell>
                                <Cell>{shopifyProductVariant.shopifyShop?.name}</Cell>
                                <Cell>{shopifyProductVariant.takeAction}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                shopifyProductVariantStore.shopifyProductVariants?.pagination &&
                <Pagination pagination={shopifyProductVariantStore.shopifyProductVariants.pagination} />
            }
        </div>
    )
})

export default PageSynchronizeProductVariants;