import EnvironmentBanner from "components/EnvironmentBanner";
import Footer from "components/Footer";
import Header from "components/Header";
import LeftPane from "components/LeftPane";
import RightPane from "components/RightPane";
import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";

const AccessPrivateContainer = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();

    const isLoggedIn = authStore.isLoggedIn;

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(routes.pageLogin);
        }

        if (location.pathname === routes.pageFront) {
            navigate(routes.pageSynchronizeProducts);
        }
    }, [isLoggedIn, location.pathname, navigate]);

    if (!isLoggedIn) return null;
    return (
        <div>
            <EnvironmentBanner />
            <div className="p-8">
                <Header />
                <div className="flex flex-row">
                    <LeftPane />
                    <RightPane />
                </div>
                <Footer />
            </div>
        </div>
    );
});

export default AccessPrivateContainer;
