import { action, makeObservable, observable } from "mobx";
import { ShopifyShopInterface } from "types/ShopifyShopInterface";

import { BaseStore } from "./BaseStore";

class ShopifyShopDetailsStore extends BaseStore {
    shopifyShop: ShopifyShopInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            shopifyShop: observable,

            resetStore: action,
            getShopifyShop: action,
            createShopifyShop: action,
            updateShopifyShop: action,
        })
    }

    resetStore = () => {
        this.shopifyShop = null;
    }

    getShopifyShop = (gid: string) => {
        return this.get(`shopify/internal/shops/${gid}`, 'shopifyShop');
    }

    createShopifyShop = (data: ShopifyShopInterface) => {
        return this.post(`shopify/internal/shops`, data, 'shopifyShop');
    }

    updateShopifyShop = (data: ShopifyShopInterface) => {
        return this.put(`shopify/internal/shops/${this.shopifyShop!.gid}`, data, 'shopifyShop');
    }
}

export const shopifyShopDetailsStore = new ShopifyShopDetailsStore();