import { FormFieldInterface } from "types/FormFieldInterface";
import * as yup from "yup";
import FormFields from "../Form/FormFields";

class ResetPasswordFormFields extends FormFields {
    public email: FormFieldInterface = {
        name: 'email',
        label: 'Email',
        validation: yup.string().required('Required').email('Email is required')
    }
}
export const resetPasswordFormFields = new ResetPasswordFormFields();