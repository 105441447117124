import { FormFieldInterface } from "types/FormFieldInterface";
import * as yup from "yup";
import FormFields from "../Form/FormFields";

class ResetPasswordTokenFormFields extends FormFields {
    public password: FormFieldInterface = {
        name: 'password',
        label: 'New password',
        validation: yup.string().required('Required').min(6, 'Password must be at least 6 characters')
    }
    public confirmPassword: FormFieldInterface = {
        name: 'confirmPassword',
        label: 'Confirm new password',
        validation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    }
}
export const resetPasswordTokenFormFields = new ResetPasswordTokenFormFields();