import Aspect4ProductVariantForm from "components/Forms/Aspect4ProductVariantForm";
import { aspect4ProductVariantFormFields } from "components/Forms/Aspect4ProductVariantForm/Aspect4ProductVariantFormFields";
import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { aspect4ProductVariantDetailsStore } from "stores/Aspect4ProductVariantDetailsStore";

const PageAspect4ProductVariantDetails = observer(() => {

    const { gid } = useParams();
    const navigate = useNavigate();
    const [showDebugData, setShowDebugData] = useState<boolean>(false);

    useEffect(() => {
        if (gid) {
            aspect4ProductVariantDetailsStore.getAspect4ProductVariant(gid);
        }

        return () => {
            aspect4ProductVariantDetailsStore.resetStore();
        }
    }, [gid])

    const aspect4ProductVariant = aspect4ProductVariantDetailsStore.aspect4ProductVariant;
    const defaultValues = aspect4ProductVariantFormFields.extractDefaultValues(aspect4ProductVariant);

    if (!gid || !aspect4ProductVariant)
        return null;

    return (
        <div>
            <h1>Aspect4 Product Variant Details</h1>
            <div className="p-4 mb-4 w-full lg:w-2/3 2xl:w-1/3 bg-white rounded-xl">
                <table>
                    <tbody>
                        <tr>
                            <td className="font-bold">Size</td>
                            <td>{aspect4ProductVariant.size}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Variant</td>
                            <td>{aspect4ProductVariant.variant}</td>
                        </tr>
                        <tr>
                            <td className="font-bold w-52 ">Item</td>
                            <td>{aspect4ProductVariant.item}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Item Text</td>
                            <td>{aspect4ProductVariant.itemText}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="clear-left">
                <button className="text-xs" onClick={() => setShowDebugData(!showDebugData)}>{showDebugData ? 'Hide Debug' : 'Debug'}</button>
            </div>

            {
                showDebugData && <div>
                    <Aspect4ProductVariantForm defaultValues={defaultValues} onSubmit={() => { }} />

                    {
                        aspect4ProductVariant?.aspect4Product && <button
                            className="my-8 btn bg-blue-500"
                            type="button"
                            onClick={() => navigate(generatePath(routes.pageAspect4ProductDetails, { gid: aspect4ProductVariant!.aspect4Product!.gid }))}
                        >
                            Goto Aspect4 Product
                        </button>
                    }
                    <div className="clear-both block">
                        <hr className="my-8" />
                        <h2>Shopify Product Variants</h2>
                        <div className="bg-white rounded-lg lg:w-1/2 p-4">
                            <div className="grid grid-cols-3 font-bold py-2 border-b ">
                                <div>Title</div>
                                <div>Price</div>
                                <div>Sync. Action</div>
                            </div>
                            {
                                aspect4ProductVariant?.shopifyProductVariants?.map(shopifyProductVariant => {
                                    return <div
                                        key={shopifyProductVariant.gid}
                                        className={`grid grid-cols-3 py-2 cursor-pointer hover:opacity-75 ${shopifyProductVariant.archivedOn != null ? 'line-through' : ''}`}
                                        onClick={() => { navigate(generatePath(routes.pageSynchronizeProductVariantDetails, { gid: shopifyProductVariant.gid })) }}
                                    >
                                        <div>{shopifyProductVariant.title}</div>
                                        <div>{shopifyProductVariant.price?.toFixed(2)}</div>
                                        <div>{shopifyProductVariant.takeAction}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </div>

    )
})

export default PageAspect4ProductVariantDetails;