import { action, makeObservable, observable } from "mobx";
import { Aspect4ProductVariantStockInterface } from "types/Aspect4ProductVariantStockInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class Aspect4ProductVariantStockStore extends BaseStore {
    aspect4ProductVariantStocks: PagingResultInterface<Aspect4ProductVariantStockInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4ProductVariantStocks: observable,

            resetStore: action,
            getAspect4ProductVariantStocks: action
        })
    }
    
    resetStore = () => {
        this.aspect4ProductVariantStocks = null;
    }

    getAspect4ProductVariantStocks = (pageNumber: number = 1) => {

        return this.getPaged(`aspect4/internal/product-variant-stocks`, this.aspect4ProductVariantStocks, 'aspect4ProductVariantStocks', pageNumber)
    }
}

export const aspect4ProductVariantStockStore = new Aspect4ProductVariantStockStore();