import _ from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PaginationInterface } from "types/PagingResultInterface";

interface Props {
    pagination: PaginationInterface
}

const Pagination = (props: Props) => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    let previous = props.pagination.currentPage > 1 ? props.pagination.currentPage - 1 : 1;
    let next = props.pagination.currentPage < props.pagination.pageCount ? props.pagination.currentPage + 1 : props.pagination.pageCount;

    let preCurrentPages = (props.pagination.currentPage > 1) ? _.range(props.pagination.currentPage - 1, 0) : [];
    let postCurrentPages = (props.pagination.currentPage < props.pagination.pageCount) ? _.range(props.pagination.currentPage + 1, props.pagination.pageCount + 1) : []

    if (postCurrentPages.length < 5) {
        preCurrentPages = _.slice(preCurrentPages, 0, 10 - postCurrentPages.length);
    } else {
        preCurrentPages = _.slice(preCurrentPages, 0, 5);
    }

    if (preCurrentPages.length < 5) {
        postCurrentPages = _.slice(postCurrentPages, 0, 10 - preCurrentPages.length);
    } else {
        postCurrentPages = _.slice(postCurrentPages, 0, 5);
    }

    preCurrentPages = preCurrentPages.reverse();

    if (props.pagination == null || props.pagination.pageCount < 2) {
        return null;
    }
    
    let queryString = '';
    searchParams.forEach((value, key) => {
        if (key !== 'page') {
            queryString += '&' + key + '=' + value;
        }
    });

    return (
        <div className="text-center mt-2">
            <button type="button" className="mr-2 px-1" onClick={() => navigate(`${location.pathname}?page=${previous}${queryString}`)} disabled={props.pagination.currentPage <= previous}>&larr;</button>
            {
                preCurrentPages.length > 0
                && preCurrentPages[0] !== 1
                && <button type="button" className="mr-2 px-1" onClick={() => navigate(`${location.pathname}?page=${preCurrentPages[0] - 1}${queryString}`)}>...</button>
            }
            {
                preCurrentPages.map((v, i) => {
                    return <button type="button" className="mr-2 px-1" onClick={() => navigate(`${location.pathname}?page=${v}${queryString}`)} key={v}>{v}</button>
                })
            }
            <button type="button" className="mr-2 border border-black rounded-md px-1">{props.pagination.currentPage}</button>
            {
                postCurrentPages.map((v, i) => {
                    return <button type="button" className="mr-2 px-1" onClick={() => navigate(`${location.pathname}?page=${v}${queryString}`)} key={v}>{v}</button>
                })
            }
            {
                postCurrentPages.length > 0
                && postCurrentPages[postCurrentPages.length - 1] !== props.pagination.pageCount
                && <button type="button" className="mr-2 px-1" onClick={() => navigate(`${location.pathname}?page=${postCurrentPages[postCurrentPages.length - 1] + 1}${queryString}`)}>...</button>
            }
            <button type="button" className="px-1" onClick={() => navigate(`${location.pathname}?page=${next}${queryString}`)} disabled={props.pagination.currentPage >= next}>&rarr;</button>
        </div>
    )
}

export default Pagination;