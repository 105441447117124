import { useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import Error from "components/Forms/Error";
import Label from "components/Forms/Label";
import { useEffect } from "react";

interface Props extends FormElementInterface {
    type?: 'text' | 'password' | 'checkbox' | 'email',
    defaultChecked?: boolean,
    className?: string,
    inputClassName?: string,
    description?: string,
    readonly?: boolean,
    disabled?: boolean
}

const Input = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    return (
        <div className={props.className || 'mb-4'}>
            <Label name={props.name} label={props.label} />
            <input
                className={props.inputClassName || (props.type !== 'checkbox' ? 'px-4 py-2 h-10 w-full rounded-sm' : 'mt-2 mb-2 w-6 h-6')}
                {...register(props.name)}
                type={props.type || 'text'}
                defaultChecked={props.defaultChecked}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                readOnly={props.readonly}
                disabled={props.disabled}
            />
            {props.description && <div className="text-xs">{props.description}</div>}
            <Error name={props.name} />
        </div>
    )
}

export default Input;