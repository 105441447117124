import AccessPrivateContainer from "components/AccessPrivateContainer";
import PageLogin from "components/Pages/PageLogin";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from "react-router-dom"
import ToastContainer from "components/ToastContainer";
import PageAspect4Products from "components/Pages/PageAspect4Products";
import PageAspect4ProductVariants from "components/Pages/PageAspect4ProductVariants";
import PageSynchronizeProducts from "components/Pages/PageSynchronizeProducts";
import PageSynchronizeProductVariants from "components/Pages/PageSynchronizeProductVariants";
import PageInventoryDownloaded from "components/Pages/PageInventoryDownloaded";
import PageInventoryReceived from "components/Pages/PageInventoryReceived";
import PageLogsAnalyze from "components/Pages/PageLogsAnalyze";
import PageLogsSynchronize from "components/Pages/PageLogsSynchronize";
import PageShopifyShops from "components/Pages/PageShopifyShops";
import PageShopifyShopDetails from "components/Pages/PageShopifyShopDetails";
import PageUsers from "components/Pages/PageUsers";
import PageUserDetails from "components/Pages/PageUserDetails";
import PageAspect4ProductDetails from "components/Pages/PageAspect4ProductDetails";
import PageAspect4ProductVariantDetails from "components/Pages/PageAspect4ProductVariantDetails";
import PageSynchronizeProductVariantDetails from "components/Pages/PageSynchronizeProductVariantDetails";
import PageSynchronizeProductDetails from "components/Pages/PageSynchronizeProductDetails";
import PageResetPassword from "components/Pages/PageResetPassword";
import PageResetPasswordToken from "components/Pages/PageResetPasswordToken";
import PageAspect4ProductImages from "components/Pages/PageAspect4ProductImages";
import AccessOpenContainer from "components/AccessOpenContainer";

export const history = createBrowserHistory();

export const routes = {
    pageLogin: '/login',
    pageResetPassword: '/reset-password',
    pageResetPasswordToken: '/reset-password/:token',

    pageFront: '/',
    
    pageSynchronizeProducts: '/synchronize/products',
    pageSynchronizeProductDetails: '/synchronize/products/:gid',
    pageSynchronizeProductVariants: '/synchronize/variants',
    pageSynchronizeProductVariantDetails: '/synchronize/variants/:gid',

    pageAspect4Products: '/aspect4/products',
    pageAspect4ProductDetails: '/aspect4/products/:gid',
    pageAspect4ProductVariants: '/aspect4/variants',
    pageAspect4ProductVariantDetails: '/aspect4/variants/:gid',
    pageAspect4ProductImages: 'aspect4/product-images',

    pageInventoryDownloaded: '/settings/inventory/downloaded',
    pageInventoryReceived: '/settings/inventory/received',

    pageLogsAnalyze: '/settings/logs/analyze',
    pageLogsSynchronize: '/settings/logs/synchronize',

    pageUsers: '/settings/access/users',
    pageCreateUser: '/settings/access/users/new',
    pageEditUser: '/settings/access/users/:gid',

    pageShopifyShops: '/settings/access/shops',
    pageCreateShopifyShop: '/settings/access/shops/new',
    pageEditShopifyShop: '/settings/access/shops/:gid',
}

const MyRoutes = () => (
    <div>
        <ToastContainer position='top-center' autoClose={5000} />
        <HistoryRouter history={history}>
            <Routes>
                <Route element={<AccessOpenContainer />}>
                    <Route path={routes.pageLogin} element={<PageLogin />} />
                    <Route path={routes.pageResetPassword} element={<PageResetPassword />} />
                    <Route path={routes.pageResetPasswordToken} element={<PageResetPasswordToken />} />
                </Route>
                <Route element={<AccessPrivateContainer />}>

                    {/* Synchronize (Shopify) */}
                    <Route path={routes.pageFront} element={<PageSynchronizeProducts />} />
                    <Route path={routes.pageSynchronizeProducts} element={<PageSynchronizeProducts />} />
                    <Route path={routes.pageSynchronizeProductDetails} element={<PageSynchronizeProductDetails />} />
                    <Route path={routes.pageSynchronizeProductVariants} element={<PageSynchronizeProductVariants />} />
                    <Route path={routes.pageSynchronizeProductVariantDetails} element={<PageSynchronizeProductVariantDetails />} />

                    {/* Aspect4 */}
                    <Route path={routes.pageAspect4Products} element={<PageAspect4Products />} />
                    <Route path={routes.pageAspect4ProductDetails} element={<PageAspect4ProductDetails />} />
                    <Route path={routes.pageAspect4ProductVariants} element={<PageAspect4ProductVariants />} />
                    <Route path={routes.pageAspect4ProductVariantDetails} element={<PageAspect4ProductVariantDetails />} />
                    <Route path={routes.pageAspect4ProductImages} element={<PageAspect4ProductImages />} />

                    {/* Inventory */}
                    <Route path={routes.pageInventoryDownloaded} element={<PageInventoryDownloaded />} />
                    <Route path={routes.pageInventoryReceived} element={<PageInventoryReceived />} />

                    {/* Logs */}
                    <Route path={routes.pageLogsAnalyze} element={<PageLogsAnalyze />} />
                    <Route path={routes.pageLogsSynchronize} element={<PageLogsSynchronize />} />

                    {/* Settings -> Users */}
                    <Route path={routes.pageUsers} element={<PageUsers />} />
                    <Route path={routes.pageCreateUser} element={<PageUserDetails />} />
                    <Route path={routes.pageEditUser} element={<PageUserDetails />} />

                    {/* Settings -> Shops */}
                    <Route path={routes.pageShopifyShops} element={<PageShopifyShops />} />
                    <Route path={routes.pageCreateShopifyShop} element={<PageShopifyShopDetails />} />
                    <Route path={routes.pageEditShopifyShop} element={<PageShopifyShopDetails />} />

                </Route>
            </Routes>
        </HistoryRouter>
    </div>
)

export default MyRoutes;