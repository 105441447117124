import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { shopifyShopFormFields } from "./ShopifyShopFormFields";
import { ShopifyShopInterface } from "types/ShopifyShopInterface";

interface Props {
    defaultValues?: ShopifyShopInterface,
    onSubmit: (data: ShopifyShopInterface) => void,
}

const ShopifyShopForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [shopifyShopFormFields.name.name]: shopifyShopFormFields.name.validation,
            [shopifyShopFormFields.languageCode.name]: shopifyShopFormFields.languageCode.validation,
            [shopifyShopFormFields.currency.name]: shopifyShopFormFields.currency.validation,
            [shopifyShopFormFields.priceList.name]: shopifyShopFormFields.priceList.validation,
            [shopifyShopFormFields.shopifyUrl.name]: shopifyShopFormFields.shopifyUrl.validation,
            [shopifyShopFormFields.readyForAnalyze.name]: shopifyShopFormFields.readyForAnalyze.validation,
            [shopifyShopFormFields.apiAccessToken.name]: shopifyShopFormFields.apiAccessToken.validation,
            [shopifyShopFormFields.apiKey.name]: shopifyShopFormFields.apiKey.validation,
            [shopifyShopFormFields.apiSecretKey.name]: shopifyShopFormFields.apiSecretKey.validation,
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            formOptions={{ defaultValues: props.defaultValues }}
            validation={validation}
            onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
        >
            <div className='grid gap-4 grid-cols-2'>
                <div>
                    <Input name={shopifyShopFormFields.name.name} label={shopifyShopFormFields.name.label} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.languageCode.name} label={shopifyShopFormFields.languageCode.label} description={shopifyShopFormFields.languageCode.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.currency.name} label={shopifyShopFormFields.currency.label} description={shopifyShopFormFields.currency.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.priceList.name} label={shopifyShopFormFields.priceList.label} description={shopifyShopFormFields.priceList.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.shopifyUrl.name} label={shopifyShopFormFields.shopifyUrl.label} description={shopifyShopFormFields.shopifyUrl.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.readyForAnalyze.name} label={shopifyShopFormFields.readyForAnalyze.label} description={shopifyShopFormFields.readyForAnalyze.description} type="checkbox" />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.apiAccessToken.name} label={shopifyShopFormFields.apiAccessToken.label} description={shopifyShopFormFields.apiAccessToken.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.apiKey.name} label={shopifyShopFormFields.apiKey.label} description={shopifyShopFormFields.apiKey.description} />
                </div>
                <div>
                    <Input name={shopifyShopFormFields.apiSecretKey.name} label={shopifyShopFormFields.apiSecretKey.label} description={shopifyShopFormFields.apiSecretKey.description} />
                </div>
            </div>
            {
                <Button
                    type='submit'
                    label='Save'
                    appearance="primary"
                />
            }
        </Form>
    )
}

export default ShopifyShopForm;