import { Outlet } from "react-router-dom";

const RightPane = () => {
    return (
        <div className="basis-10/12">
            <Outlet />
        </div>
    )
}

export default RightPane;