import { ShopifyProductVariantInterface } from "types/ShopifyProductVariantInterface";
import Form from "../Form";
import Input from "../Input";
import { shopifyProductVariantFormFields } from "./ShopifyProductVariantFormFields";

interface Props {
    defaultValues?: ShopifyProductVariantInterface,
    onSubmit: (data: ShopifyProductVariantInterface) => void,
}

const Shopify4ProductVariantForm = (props: Props) => {
    return (
        <Form
            onSubmit={() => { }}
            formOptions={{ defaultValues: props.defaultValues }}
        >
            <div className='grid gap-4 grid-cols-3'>
                <div><Input name={shopifyProductVariantFormFields.productId.name} label={shopifyProductVariantFormFields.productId.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.title.name} label={shopifyProductVariantFormFields.title.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.sku.name} label={shopifyProductVariantFormFields.sku.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.position.name} label={shopifyProductVariantFormFields.position.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.grams.name} label={shopifyProductVariantFormFields.grams.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.inventoryPolicy.name} label={shopifyProductVariantFormFields.inventoryPolicy.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.fulfillmentService.name} label={shopifyProductVariantFormFields.fulfillmentService.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.inventoryItemId.name} label={shopifyProductVariantFormFields.inventoryItemId.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.inventoryManagement.name} label={shopifyProductVariantFormFields.inventoryManagement.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.price.name} label={shopifyProductVariantFormFields.price.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.compareAtPrice.name} label={shopifyProductVariantFormFields.compareAtPrice.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.option1.name} label={shopifyProductVariantFormFields.option1.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.option2.name} label={shopifyProductVariantFormFields.option2.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.option3.name} label={shopifyProductVariantFormFields.option3.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.createdAt.name} label={shopifyProductVariantFormFields.createdAt.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.updatedAt.name} label={shopifyProductVariantFormFields.updatedAt.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.taxable.name} label={shopifyProductVariantFormFields.taxable.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.taxCode.name} label={shopifyProductVariantFormFields.taxCode.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.requiresShipping.name} label={shopifyProductVariantFormFields.requiresShipping.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.barcode.name} label={shopifyProductVariantFormFields.barcode.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.inventoryQuantity.name} label={shopifyProductVariantFormFields.inventoryQuantity.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.imageId.name} label={shopifyProductVariantFormFields.imageId.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.weight.name} label={shopifyProductVariantFormFields.weight.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.weightUnit.name} label={shopifyProductVariantFormFields.weightUnit.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.id.name} label={shopifyProductVariantFormFields.id.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.takeAction.name} label={shopifyProductVariantFormFields.takeAction.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.takeActionApprovedOn.name} label={shopifyProductVariantFormFields.takeActionApprovedOn.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.aspect4ProductVariantGid.name} label={shopifyProductVariantFormFields.aspect4ProductVariantGid.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.shopifyShopGid.name} label={shopifyProductVariantFormFields.shopifyShopGid.label} disabled={true} /></div>
                <div><Input name={shopifyProductVariantFormFields.shopifyProductGid.name} label={shopifyProductVariantFormFields.shopifyProductGid.label} disabled={true} /></div>
            </div>
        </Form>
    )
}

export default Shopify4ProductVariantForm;