import { FormFieldInterface } from "types/FormFieldInterface";
import FormFields from "../Form/FormFields";

class Aspect4ProductVariantFormFields extends FormFields{
    public gid: FormFieldInterface = {
        name: 'gid',
        label: 'gid',
    }

    public colourText: FormFieldInterface = {
        name: 'colourText',
        label: 'colourText',
    }

    public priceLevel: FormFieldInterface = {
        name: 'priceLevel',
        label: 'priceLevel',
    }

    public activColour: FormFieldInterface = {
        name: 'activColour',
        label: 'activColour',
    }

    public item: FormFieldInterface = {
        name: 'item',
        label: 'item',
    }

    public ean: FormFieldInterface = {
        name: 'ean',
        label: 'ean',
    }

    public size: FormFieldInterface = {
        name: 'size',
        label: 'size',
    }

    public itemStyle: FormFieldInterface = {
        name: 'itemStyle',
        label: 'itemStyle',
    }

    public variant: FormFieldInterface = {
        name: 'variant',
        label: 'variant',
    }

    public colourCode: FormFieldInterface = {
        name: 'colourCode',
        label: 'colourCode',
    }

    public itemText: FormFieldInterface = {
        name: 'itemText',
        label: 'itemText',
    }

    public sizeNo: FormFieldInterface = {
        name: 'sizeNo',
        label: 'sizeNo',
    }
}

export const aspect4ProductVariantFormFields = new Aspect4ProductVariantFormFields();