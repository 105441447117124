import Aspect4ProductImages from "components/Aspect4ProductImages";
import Aspect4ProductForm from "components/Forms/Aspect4ProductForm";
import { aspect4ProductFormFields } from "components/Forms/Aspect4ProductForm/Aspect4ProductFormFields";
import { routes } from "components/Routes";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { aspect4ProductDetailsStore } from "stores/Aspect4ProductDetailsStore";

const PageAspect4ProductDetails = observer(() => {

    const { gid } = useParams();
    const navigate = useNavigate();
    const [showDebugData, setShowDebugData] = useState<boolean>(false);

    useEffect(() => {
        if (gid) {
            aspect4ProductDetailsStore.getAspect4Product(gid);
        }

        return () => {
            aspect4ProductDetailsStore.resetStore();
        }
    }, [gid])

    const aspect4Product = aspect4ProductDetailsStore.aspect4Product;

    const defaultValues = aspect4ProductFormFields.extractDefaultValues(aspect4Product);

    if (!gid || !aspect4Product)
        return null

    return (
        <div>
            <h1>Aspect4 Product Details</h1>
            <div className="p-4 w-full lg:w-2/3 2xl:w-1/3 bg-white rounded-xl">
                <table>
                    <tbody>
                        <tr>
                            <td className="font-bold w-52 ">Item</td>
                            <td>{aspect4Product.item}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Item Text</td>
                            <td>{aspect4Product.itemText}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Item Color Text</td>
                            <td>{aspect4Product.itemColorText}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Item Group Text</td>
                            <td>{aspect4Product.itemGroupText}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <hr className="my-8" />
                <Aspect4ProductImages aspect4ProductGid={gid} />
            </div>

            <div className="clear-left">
                <button className="text-xs" onClick={() => setShowDebugData(!showDebugData)}>{showDebugData ? 'Hide Debug' : 'Debug'}</button>
            </div>
            {
                showDebugData && <div>
                    <Aspect4ProductForm defaultValues={defaultValues} onSubmit={() => { }} />
                    <div className="clear-both block">
                        <hr className="my-8" />
                        <h2>Shopify Products</h2>
                        <div className="bg-white rounded-lg lg:w-2/3 p-4">
                            <div className="grid grid-cols-3 font-bold py-2 border-b ">
                                <div>Title</div>
                                <div>Status</div>
                                <div>Sync. Action</div>
                            </div>
                            {
                                aspect4Product?.shopifyProducts?.map(shopifyProduct => {
                                    return <div key={shopifyProduct.gid} className="grid grid-cols-3 py-2 cursor-pointer hover:opacity-75" onClick={() => { navigate(generatePath(routes.pageSynchronizeProductDetails, { gid: shopifyProduct.gid })) }}>
                                        <div>{shopifyProduct.title}</div>
                                        <div>{shopifyProduct.status}</div>
                                        <div>{shopifyProduct.takeAction}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="">
                        <hr className="my-8" />
                        <h2>Aspect4 Product Variants</h2>
                        <div className="bg-white rounded-lg lg:w-2/3 p-4">
                            <div className="grid grid-cols-3 font-bold py-2 border-b ">
                                <div>Size</div>
                                <div>Size No</div>
                                <div>Sku</div>
                            </div>
                            {
                                aspect4Product?.aspect4ProductVariants?.map(aspect4ProductVariant => {
                                    return <div
                                        key={aspect4ProductVariant.gid}
                                        className="grid grid-cols-3 py-2 cursor-pointer hover:opacity-75"
                                        onClick={() => { navigate(generatePath(routes.pageAspect4ProductVariantDetails, { gid: aspect4ProductVariant.gid })) }}>
                                        <div>{aspect4ProductVariant.size}</div>
                                        <div>{aspect4ProductVariant.sizeNo}</div>
                                        <div>{aspect4ProductVariant.variant}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </div>

    )
})

export default PageAspect4ProductDetails;