import { RoleEnum } from "types/RoleEnum";

export function RoleEnumToString(roleEnumValue: RoleEnum) {

    switch (roleEnumValue) {
        case RoleEnum.Admin:
            return 'admin';
        case RoleEnum.User:
            return 'user';
        case RoleEnum.SuperAdmin:
            return 'super-admin';
        default:
            return null;
    }
}