import { FormFieldInterface } from "types/FormFieldInterface";
import FormFields from "../Form/FormFields";

class ShopifyProductFormFields extends FormFields{
    public gid: FormFieldInterface = {
        name: 'gid',
        label: 'gid',
    }

    public title: FormFieldInterface = {
        name: 'title',
        label: 'title',
    }

    public bodyHtml: FormFieldInterface = {
        name: 'bodyHtml',
        label: 'bodyHtml',
    }

    public vendor: FormFieldInterface = {
        name: 'vendor',
        label: 'vendor',
    }

    public productType: FormFieldInterface = {
        name: 'productType',
        label: 'productType',
    }

    public createdAt: FormFieldInterface = {
        name: 'createdAt',
        label: 'createdAt',
    }

    public handle: FormFieldInterface = {
        name: 'handle',
        label: 'handle',
    }

    public updatedAt: FormFieldInterface = {
        name: 'updatedAt',
        label: 'updatedAt',
    }

    public publishedAt: FormFieldInterface = {
        name: 'publishedAt',
        label: 'publishedAt',
    }

    public templateSuffix: FormFieldInterface = {
        name: 'templateSuffix',
        label: 'templateSuffix',
    }

    public status: FormFieldInterface = {
        name: 'status',
        label: 'status',
    }

    public publishedScope: FormFieldInterface = {
        name: 'publishedScope',
        label: 'publishedScope',
    }

    public tags: FormFieldInterface = {
        name: 'tags',
        label: 'tags',
    }

    public adminGraphQLApiId: FormFieldInterface = {
        name: 'adminGraphQLApiId',
        label: 'adminGraphQLApiId',
    }

    public id: FormFieldInterface = {
        name: 'id',
        label: 'id',
    }

    public takeAction: FormFieldInterface = {
        name: 'takeAction',
        label: 'takeAction',
    }

    public takeActionApprovedOn: FormFieldInterface = {
        name: 'takeActionApprovedOn',
        label: 'takeActionApprovedOn',
    }

    public aspect4ProductGid: FormFieldInterface = {
        name: 'aspect4ProductGid',
        label: 'aspect4ProductGid',
    }

    public shopifyShopGid: FormFieldInterface = {
        name: 'shopifyShopGid',
        label: 'shopifyShopGid',
    }

}

export const shopifyProductFormFields = new ShopifyProductFormFields();