import Pagination from "components/Pagination";
import Table from "components/Table";
import Cell from "components/Table/Cell";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { aspect4ProductVariantStockStore } from "stores/Aspect4ProductVariantStockStore";
import { Aspect4ProductVariantStockInterface } from "types/Aspect4ProductVariantStockInterface";

const PageInventoryDownloaded = observer(() => {

    const [searchParams] = useSearchParams();

    useEffect(() => {
        // pagination and filters
        let pageQuery = searchParams.get('page') ?? '1';
        let page = parseInt(pageQuery);
        aspect4ProductVariantStockStore.getAspect4ProductVariantStocks(page > 1 ? page : 1);

        return () => {
            aspect4ProductVariantStockStore.resetStore();
        }
    }, [searchParams])


    return (
        <div>
            <h1>Inventory Downloaded</h1>
            {
                aspect4ProductVariantStockStore.aspect4ProductVariantStocks?.items &&
                aspect4ProductVariantStockStore.aspect4ProductVariantStocks?.items.length > 0 &&
                <Table
                    data={aspect4ProductVariantStockStore.aspect4ProductVariantStocks.items}
                    head={[
                        { label: 'EAN', sortProperty: '' },
                        { label: 'Quantity', sortProperty: '' },
                        { label: 'Size', sortProperty: '' },
                        { label: 'Item No', sortProperty: '' },
                        { label: 'Variant Id', sortProperty: '' },
                    ]}
                    defaultSortProperty={"productId"}
                    defaultSortDirection={"desc"}
                    renderRow={(aspect4ProductVariantStock: Aspect4ProductVariantStockInterface) => {
                        return (
                            <>
                                <Cell>{aspect4ProductVariantStock.ean}</Cell>
                                <Cell>{aspect4ProductVariantStock.quantity}</Cell>
                                <Cell>{aspect4ProductVariantStock.size}</Cell>
                                <Cell>{aspect4ProductVariantStock.itemNo}</Cell>
                                <Cell>{aspect4ProductVariantStock.variantId}</Cell>
                            </>
                        )
                    }}
                />
            }
            {
                aspect4ProductVariantStockStore.aspect4ProductVariantStocks?.pagination &&
                <Pagination pagination={aspect4ProductVariantStockStore.aspect4ProductVariantStocks.pagination} />
            }
        </div>
    )
})

export default PageInventoryDownloaded