import { isShopifyImageValid } from "helpers/ShopifyImageValidation";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { aspect4ProductImagesStore } from "stores/Aspect4ProductImagesStore";
import { AnalyzeAspect4ProductImageInterface } from "types/AnalyzeAspect4ProductImageInterface";
import { AnalyzedAspect4ProductImageInterface } from "types/AnalyzedAspect4ProductImageInterface";
import * as Sentry from "@sentry/react";
import { MiniProgressSpinner } from "components/MiniProgressSpinner";

const PageAspect4ProductImages = observer(() => {

    // Is selected files shopify valid (dimensions)
    const [validSelectedFiles, setValidSelectedFiles] = useState<{ files: File[], timestamp: Date | null }>({ files: [], timestamp: null });
    const [invalidSelectedFiles, setInvalidSelectedFiles] = useState<{ files: File[], timestamp: Date | null }>({ files: [], timestamp: null });

    // Does selected files match aspect4 product 
    const [unmatchedAspect4ProductFiles, setUnmatchedAspect4ProductFiles] = useState<{ files: AnalyzedAspect4ProductImageInterface[], timestamp: Date | null }>({ files: [], timestamp: null });
    const [matchedAspect4ProductFiles, setMatchedAspect4ProductFiles] = useState<{ files: AnalyzedAspect4ProductImageInterface[], timestamp: Date | null }>({ files: [], timestamp: null });

    // Did we successfully purge existing aspect4 product images
    const [purgeFailedFiles, setPurgeFailedFiles] = useState<{ files: AnalyzedAspect4ProductImageInterface[], timestamp: Date | null }>({ files: [], timestamp: null });

    // Did selected files get uploaded or not
    const [uploadFailedFiles, setUploadFailedFiles] = useState<{ files: File[], timestamp: Date | null }>({ files: [], timestamp: null });
    const [uploadCompletedFiles, setUploadCompletedFiles] = useState<{ files: File[], timestamp: Date | null }>({ files: [], timestamp: null });

    // Statuses and message
    const [showUploadStatus, setShowUploadStatus] = useState<boolean>(false);
    const [showAnalyzeStatus, setShowAnalyzeStatus] = useState<boolean>(false);
    const [message, setMessage] = useState<any | null>(null);

    // #1 First select files and validate dimensions
    const onFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            let validSelectedFilesArr = new Array<File>();
            let invalidSelectedFilesArr = new Array<File>();
            let selectedFilesArr = Array.from(event.target.files);

            for (let i = 0; i < selectedFilesArr.length; i++) {
                let file = selectedFilesArr[i];
                let fileIsValid = await isShopifyImageValid(file);
                if (fileIsValid) {
                    validSelectedFilesArr.push(file)
                } else {
                    invalidSelectedFilesArr.push(file);
                }
            }

            setValidSelectedFiles({ files: validSelectedFilesArr, timestamp: moment().toDate() });
            setInvalidSelectedFiles({ files: invalidSelectedFilesArr, timestamp: moment().toDate() });

            if (invalidSelectedFilesArr.length > 0) {
                setMessage(<div className="text-red-600">Some of the selected files are invalid. Please try again.</div>);
            } else {
                setMessage(<div>Selected file(s):</div>);
            }

            // Reset states
            setUploadFailedFiles({ files: [], timestamp: null });
            setUploadCompletedFiles({ files: [], timestamp: null });
            setUnmatchedAspect4ProductFiles({ files: [], timestamp: null });
            setMatchedAspect4ProductFiles({ files: [], timestamp: null });
            setPurgeFailedFiles({ files: [], timestamp: null });

            setShowUploadStatus(false);
            setShowAnalyzeStatus(false);
        }
    }

    // #2 Analyze (match aspect4 product)
    const onAnalyzeAndUpload = async () => {

        var analyzeProductImages = new Array<AnalyzeAspect4ProductImageInterface>();
        validSelectedFiles.files.forEach((productImageFile, index) => {
            analyzeProductImages.push({
                index: index,
                filename: productImageFile.name
            });
        });

        let analyzedFiles = [];

        try {
            // Analyze result
            setShowAnalyzeStatus(true);
            analyzedFiles = await aspect4ProductImagesStore.analyzeProductImages(analyzeProductImages);
            setShowAnalyzeStatus(false);
        } catch (err) {
            handleError("Analyzing file(s) failed", err);
            setShowAnalyzeStatus(false);
            return;
        }

        const matchedFiles = analyzedFiles.filter((image: AnalyzedAspect4ProductImageInterface) =>
            image.aspect4ProductGid !== null && !image.message
        );
        const unmatchedFiles = analyzedFiles.filter((image: AnalyzedAspect4ProductImageInterface) =>
            image.aspect4ProductGid === null || image.message
        );

        setUnmatchedAspect4ProductFiles({ files: unmatchedFiles, timestamp: moment().toDate() });
        setMatchedAspect4ProductFiles({ files: matchedFiles, timestamp: moment().toDate() });

        // Continue upload
        if (!window.confirm(`Please confirm:\n\nSelected files will be uploaded.\n\nWarning: This action cannot be undone!`)) return;
        setMessage(<div>File(s) processing:</div>);
        await handleAspect4ProductImages(matchedFiles);
    }

    // #3 Purge existing aspect4 product images
    // #4 Upload files
    const handleAspect4ProductImages = async (matchedFiles: AnalyzedAspect4ProductImageInterface[]) => {
        // Handle per unique Aspect4ProductGid
        const uniqueAspect4ProductGids = _.uniq(_.map(matchedFiles, 'aspect4ProductGid'));

        setShowUploadStatus(true);

        for (const aspect4ProductGid of uniqueAspect4ProductGids) {
            // Find new images by aspect4ProductGid, order by w<position> (name) and then upload them
            const productImagesToUpload = matchedFiles
                .filter(file => file.aspect4ProductGid === aspect4ProductGid)
                .sort((a: AnalyzedAspect4ProductImageInterface, b: AnalyzedAspect4ProductImageInterface) => {
                    const regExp = new RegExp("/w(\\d*)\\./", "g");
                    const aPosMatch = regExp.exec(a.filename);
                    const bPosMatch = regExp.exec(b.filename);
                    return (aPosMatch ? parseInt(aPosMatch[1] ?? 0) : 0) - (bPosMatch ? parseInt(bPosMatch[1] ?? 0) : 0)
                });

            for (let index = 0; index < productImagesToUpload.length; index++) {
                const fileToUpload = validSelectedFiles.files[productImagesToUpload[index].index];
                try {
                    await uploadAspect4ProductImage(aspect4ProductGid!, fileToUpload);
                    uploadCompletedFiles.files.push(fileToUpload);
                    setUploadCompletedFiles({ files: uploadCompletedFiles.files, timestamp: moment().toDate() })
                } catch (err) {
                    handleError(`An error occured uploading file ${fileToUpload.name}`, err);
                    uploadFailedFiles.files.push(fileToUpload);
                    setUploadFailedFiles({ files: uploadFailedFiles.files, timestamp: moment().toDate() })
                    continue;
                }
            }
        }

        if (uploadFailedFiles.files.length > 0 || purgeFailedFiles.files.length > 0) {
            setMessage(<div>Processed files <span className="text-red-600">(nb: upload errors found)</span>:</div>);
            return;
        }
        setMessage(<div>Processed files:</div>);
    }

    const handleError = (toastMessage: string, err: any) => {
        toast(toastMessage, { type: "error" });
        Sentry.captureException(err);
    }

    const uploadAspect4ProductImage = async (aspect4ProductGid: string, file: File) => {
        await aspect4ProductImagesStore.uploadAspect4ProductImage(aspect4ProductGid, file);
        setUploadCompletedFiles({ files: uploadFailedFiles.files, timestamp: moment().toDate() })
    }

    const isUploadInProgress = () => {
        return showUploadStatus
            && (
                uploadFailedFiles.files.length
                + uploadCompletedFiles.files.length
                + purgeFailedFiles.files.length
                + unmatchedAspect4ProductFiles.files.length
            ) < validSelectedFiles.files.length;
    }

    const processedFilesCount = () => {
        return uploadCompletedFiles.files.length
            + uploadFailedFiles.files.length
            + purgeFailedFiles.files.length
            + unmatchedAspect4ProductFiles.files.length;
    }

    const printFileStatus = (file: File) => {

        if (invalidSelectedFiles.files.filter(found => found.name === file.name).length > 0) {
            return <span className="text-red-600">Invalid dimensions</span>
        }

        var unmatchedAspect4ProductFile = unmatchedAspect4ProductFiles.files.find(found => found.filename === file.name);
        if (unmatchedAspect4ProductFile !== undefined) {
            return <span className="text-red-600">{unmatchedAspect4ProductFile.message ?? 'No product match'}</span>
        }

        if (purgeFailedFiles.files.filter(found => found.filename === file.name).length > 0) {
            return <span className="text-red-600">Images reset error</span>
        }

        if (uploadFailedFiles.files.filter(found => found.name === file.name).length > 0) {
            return <span className="text-red-600">Upload failed</span>
        }

        if (uploadCompletedFiles.files.filter(found => found.name === file.name).length > 0) {
            return <span className="text-green-600">Upload complete</span>
        }

        return <span className="">Ready</span>
    }

    const printFilename = (file: File) => {
        // Only show link when upload is complete
        if (isUploadInProgress() === false && showUploadStatus === true) {
            const fileFound = [...matchedAspect4ProductFiles.files, ...unmatchedAspect4ProductFiles.files]
                .find(fileFound => fileFound.aspect4ProductGid && fileFound.filename === file.name)

            return fileFound
                ? <a href={`/aspect4/products/${fileFound.aspect4ProductGid}`} className="underline">{file.name}</a>
                : file.name
        }
        return file.name;
    }

    const printFileSize = (file: File) => {
        return (file.size / (1048576)).toFixed(2);
    }

    const showAnalyzeAndUploadButton = () => {
        return validSelectedFiles.files.length > 0
            && invalidSelectedFiles.files.length === 0
            && showUploadStatus === false
            && showAnalyzeStatus === false;
    }

    const selectedFiles = [...invalidSelectedFiles.files, ...validSelectedFiles.files];

    return <div>
        <h1>Upload images</h1>

        <label className={`btn btn-primary inline-block p-0 m-0 ${isUploadInProgress() ? 'disabled' : ''}`}>
            Select file(s)
            <input
                type="file"
                accept="image/*"
                id="files"
                name="files"
                multiple
                onChange={onFileSelect}
                className="hidden"
                disabled={isUploadInProgress()}
            />
        </label>
        {
            showAnalyzeAndUploadButton()
            && <button className="btn btn-primary ml-4" type="button" onClick={onAnalyzeAndUpload}>Analyze & upload</button>
        }
        {
            showAnalyzeStatus && <div className="ml-4 px-4 py-2 inline-block border border-green-500 rounded-full">
                <MiniProgressSpinner />
                Analyzing.. please wait..
            </div>
        }
        {
            showUploadStatus && <div className="ml-4 px-4 py-2 inline-block border border-green-500 rounded-full">
                {isUploadInProgress() && <MiniProgressSpinner />}
                Processed: {processedFilesCount()} / {validSelectedFiles.files.length}
            </div>
        }
        {
            selectedFiles.length > 0 &&
            <div>
                {
                    message !== null &&
                    <div className="font-bold my-4">{message}</div>
                }
                <div className="bg-white rounded-xl w-2/3 px-4 py-2 mt-4">
                    <div className="grid grid-cols-5 py-2" key={-1}>
                        <div className="col-span-3 font-bold">Filename</div>
                        <div className="font-bold">Size</div>
                        <div className="font-bold">Status</div>
                    </div>
                    {
                        selectedFiles.map((file: File, index: number) => {
                            return <div className="grid grid-cols-5 py-2 border-t" key={index}>
                                <div className="col-span-3">{printFilename(file)}</div>
                                <div>{printFileSize(file)} <span className="text-xs">MB</span></div>
                                <div>{printFileStatus(file)}</div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
    </div>
})

export default PageAspect4ProductImages;