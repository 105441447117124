import { TableSortLabel } from '@material-ui/core';

interface Props {
    label: string,
    sortProperty: string,
    activeProperty: string,
    onSort: (property: string, orderFunction?: (row: any) => void) => void,
    direction: 'asc' | 'desc',
    rowFormat?: (row: any) => void
}

const SortLabel = (props: Props) => (
    <TableSortLabel
        onClick={() => props.onSort(props.sortProperty, props.rowFormat ? () => props.rowFormat : undefined)}
        active={props.activeProperty === props.sortProperty}
        direction={props.direction}>
        {props.label}
    </TableSortLabel>
)

export default SortLabel