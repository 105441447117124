import { action, makeObservable, observable } from "mobx";
import { Aspect4ProductInterface } from "types/Aspect4ProductInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class Aspect4ProductsStore extends BaseStore {
    aspect4Products: PagingResultInterface<Aspect4ProductInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            aspect4Products: observable,

            resetStore: action,
            getAspect4Products: action
        })
    }

    resetStore = () => {
        this.aspect4Products = null;
    }

    getAspect4Products = (pageNumber: number = 1, search: string | null) => {
        const url = `aspect4/internal/products?` + (search != null ? `&search=${search}` : ``);
        return this.getPaged(url, this.aspect4Products, 'aspect4Products', pageNumber)
    }
}

export const aspect4ProductStore = new Aspect4ProductsStore();