import Form from "../Form";
import Input from "../Input";
import { shopifyProductFormFields } from "./ShopifyProductFormFields";
import { ShopifyProductInterface } from "types/ShopifyProductInterface";

interface Props {
    defaultValues?: ShopifyProductInterface,
    onSubmit: (data: ShopifyProductInterface) => void,
}

const ShopifyProductForm = (props: Props) => {
    return (
        <Form
            onSubmit={() => { }}
            formOptions={{ defaultValues: props.defaultValues }}
        >
            <div className='grid gap-4 grid-cols-3'>
                <div><Input name={shopifyProductFormFields.gid.name} label={shopifyProductFormFields.gid.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.title.name} label={shopifyProductFormFields.title.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.bodyHtml.name} label={shopifyProductFormFields.bodyHtml.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.vendor.name} label={shopifyProductFormFields.vendor.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.productType.name} label={shopifyProductFormFields.productType.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.createdAt.name} label={shopifyProductFormFields.createdAt.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.handle.name} label={shopifyProductFormFields.handle.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.updatedAt.name} label={shopifyProductFormFields.updatedAt.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.publishedAt.name} label={shopifyProductFormFields.publishedAt.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.templateSuffix.name} label={shopifyProductFormFields.templateSuffix.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.status.name} label={shopifyProductFormFields.status.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.publishedScope.name} label={shopifyProductFormFields.publishedScope.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.tags.name} label={shopifyProductFormFields.tags.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.adminGraphQLApiId.name} label={shopifyProductFormFields.adminGraphQLApiId.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.id.name} label={shopifyProductFormFields.id.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.takeAction.name} label={shopifyProductFormFields.takeAction.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.takeActionApprovedOn.name} label={shopifyProductFormFields.takeActionApprovedOn.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.aspect4ProductGid.name} label={shopifyProductFormFields.aspect4ProductGid.label} disabled={true} /></div>
                <div><Input name={shopifyProductFormFields.shopifyShopGid.name} label={shopifyProductFormFields.shopifyShopGid.label} disabled={true} /></div>
            </div>
        </Form>
    )
}

export default ShopifyProductForm;